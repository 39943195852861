import { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
interface Props {
  kindClass?: string;
  title: string;
  icon: string;
  description?: string;
  score?: number;
}

export const ProjectItem = (props: Props) => {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  return (
    <Card className={`${props.kindClass}`}>
      <Card.Header className='d-flex justify-content-between'>
        <div className='title_box'>
          <img src={props.icon} alt='' height={32} />
          <b className='ms-1'>{props.title}</b>
        </div>
      </Card.Header>
      <Card.Body>
        <div className='d-flex'>
          <div className='content'>
            <p>{props.description}</p>
            <div className='text-center'>
              <button>view</button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
