import { Card, Dropdown } from "react-bootstrap";
import vdots from "../assets/images/icons/vdot.svg";
import adapt_icon from "../assets/images/images/adapt.png";
import build_icon from "../assets/images/images/build.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Activity } from "../models";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  addClassShow,
  addFolderShow,
  curActivity,
  curClass,
  selectActivityId,
  selectedActivityInLesson,
  UserInfo,
} from "../store";
interface Props {
  kindClass?: string;
  activity: Activity;
  disabledLesson: boolean;
  onAdapt?: (activityId: string) => void;
  onDelete: (activityId: string) => void;
  onLessonAction?: () => void;
  icon: string;
  score?: number;
}

export const ActivityBox = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const setCurActivity = useSetRecoilState(curActivity);
  const [_activityId, set_activityId] = useRecoilState(selectActivityId);
  const set_cur_class = useSetRecoilState(curClass);
  const setActivityInLesson = useSetRecoilState(selectedActivityInLesson);
  const userinfo = useRecoilValue(UserInfo);
  const [showaddClass, setShowAddClass] = useRecoilState(addClassShow);
  const [showaddFolder, setShowAddFolder] = useRecoilState(addFolderShow);

  const goDetail = () => {
    setCurActivity(props.activity);
    if (props.activity.isDraft) {
      if (props.activity.activity_Adaptation) {
        navigate("/build/adapt/detail");
      } else {
        navigate("/build/template");
      }
    } else {
      navigate("/build/template/detail/0");
    }
  };
  const goLesson = () => {
    if (props.onLessonAction) {
      props.onLessonAction();
    } else {
      if (location.pathname.indexOf("/classdetail") === 0) {
        if (params && params?.clsId) {
          set_cur_class(params?.clsId);
        }
      } else {
        set_cur_class("");
      }
      setActivityInLesson(props.activity);
      navigate("/build/lesson");
    }
  };
  return (
    <Card
      className={`${props.activity.activity_Adaptation ? "adapt" : "default"} `}
    >
      <Card.Header className="d-flex justify-content-between">
        <div className="title_box cursor flex-fill d-flex" onClick={goDetail}>
          <img src={props.icon} alt="" height={24} />
          <b className="ms-1 text-nowrap overflow-hidden">
            {props.activity.activity_Name}
          </b>
        </div>
        {/* <Dropdown className='activity_menu'>
          <Dropdown.Toggle>
            <img src={vdots} alt='' className='cursor' />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {!props.activity.isDraft && (
              <>
                <Dropdown.Item
                  as='li'
                  className='cursor'
                  onClick={() => {
                    set_activityId(props.activity.activity_Id);
                    setShowAddClass(true);
                  }}
                >
                  ADD TO CLASS
                </Dropdown.Item>
                <Dropdown.Item
                  as='li'
                  className='cursor'
                  onClick={() => {
                    set_activityId(props.activity.activity_Id);
                    setShowAddFolder(true);
                  }}
                >
                  ADD TO FOLDER
                </Dropdown.Item>
              </>
            )}

            {props.activity.isDraft && (
              <Dropdown.Item
                as='li'
                className='cursor'
                onClick={() => props.onDelete(props.activity.activity_Id)}
              >
                Delete
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown> */}
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <div className="content">
            <div>
              <span>
                {props.activity.activity_PrepTime} <b>prep</b>
              </span>
              <span className="ms-2">
                {props.activity.activity_UseTime} <b>class time</b>
              </span>
            </div>
            <p>{props.activity.activity_Desc}</p>
          </div>
          {/* <div className="controls d-flex align-content-center flex-wrap">
            {props.activity.matchScore && (
              <div className="my-3">
                <h6
                  className={`text-center score ${
                    props.activity.matchScore >= 70
                      ? "score_green"
                      : props.activity.matchScore >= 50
                      ? "score_warn"
                      : "score_low"
                  }`}
                >
                  {props.activity.matchScore.toFixed(0)}
                  {"%"}
                </h6>
              </div>
            )}
            <div className="my-3">
              {!(props.activity.isDraft || props.activity.isPrivate) && (
                <img
                  src={adapt_icon}
                  className="mx-auto cursor"
                  alt=""
                  height={23}
                  onClick={() => {
                    if (props.onAdapt && props.activity)
                      props.onAdapt(props.activity.activity_Id);
                  }}
                />
              )}
            </div>
            <div className="my-3">
              {!props.activity.isDraft && !props.disabledLesson && (
                <img
                  src={build_icon}
                  className="mx-auto cursor"
                  height={23}
                  alt=""
                  onClick={() => goLesson()}
                />
              )}
            </div>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};
