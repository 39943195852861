import { useState } from 'react';

import gray_find from '../assets/images/icons/gray_find.svg';
import man_icon from '../assets/images/icons/input_man_icon.svg';
import { useNavigate } from 'react-router-dom';

import { CommunityLayout } from '../components/Layouts/CommunityLayout';
import { Container, Form, FormControl, InputGroup } from 'react-bootstrap';
import { ManItem } from '../components/ManItem';
export const CommunityFindFriends = () => {
  const navigate = useNavigate();
  const [inviteModal, setinviteModal] = useState<boolean>(false);
  const [leaveModal, setleaveModal] = useState<boolean>(false);

  return (
    <CommunityLayout title='Find Friends' isFriend={true} isFindFriend={true}>
      <Container className='ps-md-5'>
        <div className='d-flex justify-content-between'>
          <div className='flex-fill pe-3'>
            <InputGroup className='mb-2 my_effect'>
              <InputGroup.Text id='basic-addon1'>
                <img src={gray_find} alt='' />
              </InputGroup.Text>
              <FormControl
                placeholder='content keyword search'
                aria-label='Username'
                aria-describedby='basic-addon1'
              />
            </InputGroup>
          </div>
          <div className='wd-130'>
            <Form.Select
              aria-label='sort by relevant'
              className='fnt_robo sort_cell '
            >
              <option>sort by relevant</option>
            </Form.Select>
          </div>
        </div>
        <div className='friends_list'>
          <ManItem
            name='Thomas Anderson'
            address='Chiang Mai. Thailand'
            icon={man_icon}
          />
          <ManItem
            name='Firstname Lastname'
            address='City, Country'
            icon={man_icon}
          />

          <ManItem
            name='Thomas Anderson'
            address='Chiang Mai. Thailand'
            icon={man_icon}
          />
          <ManItem
            name='Firstname Lastname'
            address='City, Country'
            icon={man_icon}
          />
          <ManItem
            name='Thomas Anderson'
            address='Chiang Mai. Thailand'
            icon={man_icon}
          />
          <ManItem
            name='Firstname Lastname'
            address='City, Country'
            icon={man_icon}
          />
        </div>
      </Container>
    </CommunityLayout>
  );
};
