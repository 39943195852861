import React, { PropsWithChildren } from 'react';
import { Footer } from './Footers/Footer';
import { TopHeader } from './Headers/TopHeader';

interface Props {
  wrapClass?: string;
  topClass?: string;
}
export const Layout = (props: PropsWithChildren<Props>) => {
  const { wrapClass, topClass, children } = props;
  return (
    <div className={wrapClass}>
      <TopHeader wrapClass={topClass} />
      <main>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};
