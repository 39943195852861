import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import close from '../../assets/images/icons/red_close.svg';
interface Props {
  show: boolean;
  onOk: () => void;
}
export const LeaveGroupModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className='dashboard_video'
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>
          warning!
          <img
            src={close}
            alt=''
            onClick={() => {
              props.onOk();
            }}
            className='closeBtn'
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        <Container>
          <div className='my-3'>
            <p className='text-center fs-5'>
              Are you sure you want to leave this group?
            </p>
          </div>

          <div className='d-flex justify-content-evenly pt-3'>
            <button className='bg_warn rounded_3 py-2 px-4 fs-4'>cancel</button>
            <button className=' border_warn bg-white rounded_3 py-2 px-4 fs-4'>
              confirm
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
