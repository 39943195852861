/**
 * Publish Activity page : /build/public/activity
 */
import { NoFooterLayout } from '../components/Layouts/NoFooterLayout';
import { useNavigate } from 'react-router-dom';
import { Card, Container } from 'react-bootstrap';
import fist from '../assets/images/images/two_fist.png';
const PublishActivity = () => {
  const navigate = useNavigate();
  return (
    <NoFooterLayout topClass='box_shadow'>
      <div className='signup_wrap'>
        <Container className='pt-5'>
          <Card className='sign_card mx-auto vw_high'>
            <Card.Header>Activity published!</Card.Header>
            <Card.Body>
              <Container className='verify_body'>
                <p className='text-center'>
                  Thank you for providing inspiration to educators everywhere.
                </p>
                <div className='img_block align-items-center'>
                  <img
                    src={fist}
                    alt=''
                    className='mx-auto d-block'
                    width={260}
                  />
                </div>

                <div className='mt-3 d-flex justify-content-around'>
                  <button
                    className='bg_warn rounded_3 py-2 px-4 fs-4 w_100'
                    onClick={() => {
                      navigate('/dashboard');
                    }}
                  >
                    back to workshop
                  </button>
                  <button
                    className='bg_warn rounded_3 py-2 px-4 fs-4 w_100'
                    onClick={() => {
                      navigate('/build/template/detail/1');
                    }}
                  >
                    view activity page
                  </button>
                </div>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </NoFooterLayout>
  );
};
export default PublishActivity;
