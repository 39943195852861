import { MainLayout } from '../components/Layouts/MainLayout';
import left_icon from '../assets/images/icons/left_arrow.svg';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import gray_find from '../assets/images/icons/gray_find.svg';

import { AdaptItem } from '../components/AdaptItem';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAdaptActivities } from '../services/Query';
/**
 *
 * Adaptations page : /build/classedit/
 */
export const Adaptations = () => {
  const navigate = useNavigate();
  const { activityId } = useParams();
  const [page, setpage] = useState<number>(1);
  const { isLoading, data: pageData } = useAdaptActivities(activityId, page);
  return (
    <MainLayout>
      <div className='d-flex'>
        <div className='dash_content  w-100'>
          <div className='caption d-flex'>
            <button
              className='prm_back'
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={left_icon} alt='' className='me-1' height={18} />
              back
            </button>
            <h3 className='flex-fill text-center'>
              Adaptations of Verb Tense Story Chain
            </h3>
          </div>
          <div className='d-flex justify-content-center mt-2'>
            <div className='pe-3'>
              <InputGroup className='mb-2 my_effect'>
                <InputGroup.Text id='basic-addon1'>
                  <img src={gray_find} alt='' />
                </InputGroup.Text>
                <FormControl
                  placeholder='content keyword search'
                  aria-label='Username'
                  aria-describedby='basic-addon1'
                />
              </InputGroup>
            </div>
            <div className='wd-160'>
              <Form.Select
                aria-label='sort by relevant'
                className='fnt_robo sort_cell text_info '
              >
                <option>sort by relevant</option>
              </Form.Select>
            </div>
          </div>
          <div className='template_list mt-5'>
            <div className='container mt-3'>
              <div className='cell_box row ps-3'>
                {pageData &&
                  pageData.data &&
                  pageData.data.length > 0 &&
                  pageData.data.map((it, idx) => (
                    <div className='col-xl-4 col-lg-6 mb-sm-4' key={idx}>
                      <AdaptItem kindClass='adapt' activity={it} />
                    </div>
                  ))}
              </div>
              <div className='container mt-3 text-center'>
                {page > 1 && (
                  <span
                    className='text_info cursor fw-normal'
                    onClick={() => {
                      setpage((page) => --page);
                    }}
                  >
                    {`<`} previous page
                  </span>
                )}
                {pageData && page < pageData.total && (
                  <span
                    className='text_info cursor fw-normal'
                    onClick={() => {
                      setpage((page) => ++page);
                    }}
                  >
                    next page {`<`}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
