import { useEffect, useRef, useState } from 'react';
import { MainLayout } from '../components/Layouts/MainLayout';
import list_icon from '../assets/images/images/list.png';
import circle_help from '../assets/images/icons/circle_help.svg';
import drop_down from '../assets/images/icons/drop_down.svg';
import single_man_on from '../assets/images/icons/single_man_on.svg';
import single_man from '../assets/images/icons/single_man.svg';
import double_man_on from '../assets/images/icons/double_man_on.svg';
import double_man from '../assets/images/icons/double_man.svg';
import thrd_man_on from '../assets/images/icons/thrd_man_on.svg';
import thrd_man from '../assets/images/icons/thrd_man.svg';
import back_btn from '../assets/images/images/back_arrow@2x.png';
import recycle_on from '../assets/images/icons/recycle_on.svg';
import recycle from '../assets/images/icons/recycle.svg';
import move_icon_on from '../assets/images/icons/move_icon_on.svg';
import move_icon from '../assets/images/icons/move_icon.svg';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  confirmAdaptShow,
  curClass,
  findCata,
  findClass,
  orgActivityInAdapt,
  selectedActivityInLesson,
  UserInfo,
} from '../store';
import { useDeleteActivity, useFindActivities } from '../services/Query';
import ReactLoading from 'react-loading';
import { ActivityBox } from '../components/ActivityBox';
import { useNavigate } from 'react-router-dom';
import {
  activityTypeArry,
  LangFocus,
  lessonStages,
  MessageString,
  Skills,
  SpecStr,
  timeArry,
} from '../models/defaults';
import { Activity, Classes, findRequest } from '../models';
import { ConfirmModal } from '../components/modals/ConfirmModal';
/**
 *
 * Find result page : /find/results
 */
export const FindResults = () => {
  const [type, setType] = useState<number>(0);
  const [catagoryDetail, setCatagoryDetail] = useState<boolean>(false);
  const userinfo = useRecoilValue(UserInfo);
  const findClassId = useRecoilValue(findClass);
  const [fRequest, setFindRequest] = useRecoilState(findCata);
  const navigate = useNavigate();
  const [confirmAdapt, setconfirmAdaptShow] = useRecoilState(confirmAdaptShow);
  const [orgActivity, setOrgActivity] = useRecoilState(orgActivityInAdapt);
  const set_cur_class = useSetRecoilState(curClass);
  const setActivityInLesson = useSetRecoilState(selectedActivityInLesson);

  const [langFocus, setlangFocus] = useState<string[]>(
    fRequest.languageFocus.split(SpecStr.split_common)
  );
  const [lessonFocus, setlessonFocus] = useState<string[]>(
    fRequest.lessonFocus.split(SpecStr.split_common)
  );
  const [skillFocus, setSkillFocus] = useState<string[]>(
    fRequest.skillFocus.split(SpecStr.split_common)
  );
  const [actType, setActType] = useState<string[]>(
    fRequest.activity_Type.split(SpecStr.split_common)
  );
  const [deleteMsg, setdeleteMsg] = useState('');
  const [deleteShow, setdeleteShow] = useState(false);
  const prepOp = useRef<string>('');
  const classOp = useRef<string>('');
  const prepVal = useRef<string>('');
  const classVal = useRef<string>('');
  const checkedIds = useRef<string[]>([]);
  const selectedActivity = useRef<Activity | undefined>(undefined);
  const onPrepChange = () => {
    let pos = timeArry.findIndex((it) => it == prepVal.current);
    if (pos < 0) pos = 0;
    let times = timeArry.slice(pos);
    if (prepOp.current !== '<') {
      times = timeArry.slice(0, pos + 1);
    }
    setFindRequest((prev) => {
      const nw = { ...prev, prepTime: times.join(SpecStr.split_common) };
      return nw;
    });
  };
  const onClassTimeChange = () => {
    let pos = timeArry.findIndex((it) => it === classVal.current) + 1;
    if (pos < 0) pos = 1;
    let times = timeArry.slice(pos - 1);
    if (classOp.current !== '<') {
      times = timeArry.slice(0, pos);
    }
    setFindRequest((prev) => {
      const nw = { ...prev, useTime: times.join(SpecStr.split_common) };
      return nw;
    });
  };
  const {
    isLoading,
    data: searchActivities,
    refetch: search,
  } = useFindActivities(userinfo?.member_Id, fRequest);
  useEffect(() => {
    search();
  }, []);
  const { isLoading: isDeleting, mutate: onDeleteActivity } =
    useDeleteActivity();
  const deleteId = useRef<string>('');
  const deleteTemplate = (activityId: string) => {
    deleteId.current = activityId;
    setdeleteMsg(MessageString.deleteLesson);
    setdeleteShow(true);
  };
  const [goBuildLesson, setgoBuildLesson] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<HTMLImageElement | null>(null);
  const ref = useRef(null);
  const [classInfo, setclassInfo] = useState<Classes | undefined>(undefined);
  useEffect(() => {
    if (userinfo) {
      const cls = userinfo.myClasses.find((it) => it.class_Id === findClassId);
      setclassInfo(cls);
    }
  }, [userinfo]);

  return (
    <MainLayout>
      <div className='d-flex' ref={ref}>
        <div className='left_menu'>
          {catagoryDetail ? (
            <>
              <h5 className='button'>CATEGORY TAGS</h5>
              <h6 className='text-center text-secondary'>
                select additional category tags
              </h6>
              <ul className='ps-0 pt-0 ms-0'>
                <li>language focus</li>
                <li>
                  {LangFocus.map((it, idx) => (
                    <span
                      className={`btn-box mb-1 cursor ${
                        langFocus.includes(it) ? 'selected' : ''
                      }`}
                      key={idx}
                      onClick={() => {
                        if (langFocus.includes(it)) {
                          const nwVals: string[] = [];
                          langFocus.forEach((element) => {
                            if (element !== it) nwVals.push(element);
                          });
                          setlangFocus(nwVals);
                        } else {
                          setlangFocus((prev) => [...prev, it]);
                        }
                      }}
                    >
                      {it}
                    </span>
                  ))}
                </li>

                <li className='mt-3'>skill focus</li>
                <li>
                  {Skills.map((it, idx) => (
                    <span
                      className={`btn-box mb-1 cursor ${
                        skillFocus.includes(it) ? 'selected' : ''
                      }`}
                      key={idx}
                      onClick={() => {
                        if (skillFocus.includes(it)) {
                          const nwVals: string[] = [];
                          skillFocus.forEach((element) => {
                            if (element !== it) nwVals.push(element);
                          });
                          setSkillFocus(nwVals);
                        } else {
                          setSkillFocus((prev) => [...prev, it]);
                        }
                      }}
                    >
                      {it}
                    </span>
                  ))}
                </li>
                <li className='mt-3'>lesson stage</li>
                <li>
                  {lessonStages.map((it, idx) => (
                    <span
                      className={`btn-box mb-1 cursor ${
                        lessonFocus.includes(it) ? 'selected' : ''
                      }`}
                      key={idx}
                      onClick={() => {
                        if (lessonFocus.includes(it)) {
                          const nwVals: string[] = [];
                          lessonFocus.forEach((element) => {
                            if (element !== it) nwVals.push(element);
                          });
                          setlessonFocus(nwVals);
                        } else {
                          setlessonFocus((prev) => [...prev, it]);
                        }
                      }}
                    >
                      {it}
                    </span>
                  ))}
                </li>
                <li className='mt-3'>activity type</li>
                <li>
                  {activityTypeArry.map((it, idx) => (
                    <span
                      className={`btn-box mb-1 cursor ${
                        actType.includes(it) ? 'selected' : ''
                      }`}
                      key={idx}
                      onClick={() => {
                        if (actType.includes(it)) {
                          const nwVals: string[] = [];
                          actType.forEach((element) => {
                            if (element !== it) nwVals.push(element);
                          });
                          setActType(nwVals);
                        } else {
                          setActType((prev) => [...prev, it]);
                        }
                      }}
                    >
                      {it}
                    </span>
                  ))}
                </li>
                <li className='text-center my-1 ps-0'>
                  <span
                    className='btn-toggle-box d-inline-block cursor'
                    onClick={() => {
                      const req: findRequest = {
                        languageFocus: langFocus.join(SpecStr.split_common),
                        lessonFocus: lessonFocus.join(SpecStr.split_common),
                        skillFocus: skillFocus.join(SpecStr.split_common),
                        activity_Type: actType.join(SpecStr.split_common),
                        classId: fRequest.classId,
                        prepTime: '',
                        useTime: '',
                      };
                      setFindRequest(req);
                      setCatagoryDetail(false);
                    }}
                  >
                    Done
                  </span>
                </li>
              </ul>
            </>
          ) : (
            <>
              <div className='text-center find_title'>
                <h5>
                  basic filters
                  <img
                    src={circle_help}
                    alt=''
                    className='cursor'
                    onClick={(ev) => {
                      setShow(!show);
                      setTarget(ev.currentTarget);
                    }}
                  />
                </h5>
              </div>
              <h5 className='button'>
                CLASS:{` `}
                {
                  userinfo?.myClasses.find((it) => it.class_Id === findClassId)
                    ?.class_Title
                }
                <img src={drop_down} alt='' />
              </h5>
              <ul className='category_kind ps-0 pt-0 ms-0'>
                <li className='mb-0'>
                  <label htmlFor=''>students:</label>
                </li>
                <li>
                  <b>{classInfo?.class_ClassSize}</b>
                </li>
                <li className='mb-0'>
                  <label htmlFor=''>age(s): </label>
                </li>
                <li>
                  <b>{classInfo?.class_AgeRange}</b>
                </li>
                <li className='mb-0'>
                  <label htmlFor=''>location:</label>
                </li>
                <li>
                  <b>
                    {classInfo?.class_City}
                    {classInfo?.class_City ? ', ' : ' '}
                    {classInfo?.class_Country}
                  </b>
                </li>
                <li className='mb-0'>
                  <label htmlFor=''>proficiency:</label>
                </li>
                <li>
                  <b>
                    {
                      userinfo?.myClasses.find(
                        (it) => it.class_Id === findClassId
                      )?.class_ProficiencyLevel
                    }
                  </b>{' '}
                </li>
                <li className='mb-0'>
                  <label htmlFor=''>energy:</label>
                </li>
                <li>
                  <b>
                    {
                      userinfo?.myClasses.find(
                        (it) => it.class_Id === findClassId
                      )?.class_EnegryLevel
                    }
                  </b>
                </li>
              </ul>
              <h5 className='button'>CATEGORY TAGS</h5>
              <ul className='ps-0 pt-0 ms-0'>
                <li>{fRequest.activity_Type !== '' && 'activity type'}</li>
                {fRequest.activity_Type !== '' &&
                  fRequest.activity_Type
                    .split(SpecStr.split_common)
                    .filter((it) => it !== '')
                    .map((it, idx) => (
                      <li key={idx}>
                        <span className='btn-box my-1'>{it}</span>
                      </li>
                    ))}
                <li>{fRequest.languageFocus !== '' && 'language focus'}</li>
                {fRequest.languageFocus !== '' &&
                  fRequest.languageFocus
                    .split(SpecStr.split_common)
                    .filter((it) => it !== '')
                    .map((it, idx) => (
                      <li key={idx}>
                        <span className='btn-box my-1'>{it}</span>
                      </li>
                    ))}
                <li>{fRequest.lessonFocus !== '' && 'lesson focus'}</li>
                {fRequest.lessonFocus !== '' &&
                  fRequest.lessonFocus
                    .split(SpecStr.split_common)
                    .filter((it) => it !== '')
                    .map((it, idx) => (
                      <li key={idx}>
                        <span className='btn-box my-1'>{it}</span>
                      </li>
                    ))}
                <li>{fRequest.skillFocus !== '' && 'skill focus'}</li>
                {fRequest.skillFocus !== '' &&
                  fRequest.skillFocus
                    .split(SpecStr.split_common)
                    .filter((it) => it !== '')
                    .map((it, idx) => (
                      <li key={idx}>
                        <span className='btn-box my-1'>{it}</span>
                      </li>
                    ))}
                <li className='text-center my-1 ps-0'>
                  <button
                    className='btn-toggle-box d-inline-block'
                    onClick={() => {
                      setCatagoryDetail(true);
                    }}
                  >
                    view / add more tags
                  </button>
                </li>
              </ul>
              <h5 className='button'>MORE FILTERS</h5>
              <div>
                <div className='filer_box ms-xl-3 ms-3'>
                  <h6 className='mb-0 mt-3'>
                    prep time{' '}
                    <img
                      src={circle_help}
                      alt=''
                      className='cursor'
                      onClick={(ev) => {
                        setShow(!show);
                        setTarget(ev.currentTarget);
                      }}
                    />
                  </h6>
                  <div>
                    <select
                      className='rounded-3 mb-1'
                      onChange={(e) => {
                        if (prepOp.current !== e.target.value) {
                          prepOp.current = e.target.value;
                          onPrepChange();
                        }
                      }}
                    >
                      <option value='>'>no more than</option>
                      <option value='<'>no less than</option>
                    </select>
                    <select
                      className='ms-sm-1  rounded-3 mb-1'
                      onChange={(e) => {
                        const val = e.target.value;
                        if (prepVal.current !== val) {
                          prepVal.current = val;
                          onPrepChange();
                        }
                      }}
                    >
                      <option value=''></option>
                      {timeArry.map((it, idx) => (
                        <option value={it} key={idx}>
                          {it}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='filer_box ms-xl-3 ms-3'>
                  <h6 className='mb-0 mt-3'>
                    class time{' '}
                    <img
                      src={circle_help}
                      alt=''
                      className='cursor'
                      onClick={(ev) => {
                        setShow(!show);
                        setTarget(ev.currentTarget);
                      }}
                    />
                  </h6>
                  <div>
                    <select
                      className='rounded-3 mb-1'
                      onChange={(e) => {
                        classOp.current = e.target.value;
                        onClassTimeChange();
                      }}
                    >
                      <option value='>'>no more than</option>
                      <option value='<'>no less than</option>
                    </select>
                    <select
                      className='ms-sm-1 rounded-3 mb-1'
                      onChange={(e) => {
                        const val = e.target.value;
                        classVal.current = val;
                        onClassTimeChange();
                      }}
                    >
                      <option value=''></option>
                      {timeArry.map((it, idx) => (
                        <option value={it} key={idx}>
                          {it}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='mt-4 d-none'>
                    <div className='d-flex justify-content-between pe-5'>
                      <img
                        src={type === 1 ? single_man_on : single_man}
                        alt=''
                        onClick={() => {
                          setType(1);
                        }}
                      />
                      <img
                        src={type === 2 ? double_man_on : double_man}
                        alt=''
                        onClick={() => {
                          setType(2);
                        }}
                      />
                      <img
                        src={type === 3 ? thrd_man_on : thrd_man}
                        alt=''
                        onClick={() => {
                          setType(3);
                        }}
                      />
                    </div>
                    <div className='d-flex justify-content-evenly pe-5 mt-3'>
                      <img
                        src={type === 4 ? recycle_on : recycle}
                        alt=''
                        onClick={() => {
                          setType(4);
                        }}
                      />
                      <img
                        src={type === 5 ? move_icon_on : move_icon}
                        alt=''
                        onClick={() => {
                          setType(5);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='flex-fill'>
          <div className='caption d-flex justify-content-between mt-4 px-md-1 px-lg-2 px-xl-5'>
            <div className='signup_wrap'>
              <img
                src={back_btn}
                width={125}
                alt=''
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div>
              {!isLoading && (
                <div>
                  (results :
                  <b className='mx-1'>
                    {
                      searchActivities?.filter((it) => !it.activity_Adaptation)
                        ?.length
                    }
                  </b>
                  activity templates,{' '}
                  <b className='mx-1'>
                    {
                      searchActivities?.filter((it) => it.activity_Adaptation)
                        .length
                    }
                  </b>
                  adaptations)
                </div>
              )}
            </div>
          </div>
          <div className='dash_content mx-xxl-auto'>
            <div className='template_list mt-5 ps-0'>
              <div className='container mt-3'>
                <div className='cell_box row ps-3'>
                  {isLoading && (
                    <div className='w-100 text-center py-4 '>
                      <ReactLoading
                        type='spinningBubbles'
                        className='mx-auto my-auto'
                        height={'80px'}
                        width={'80px'}
                        color='#666666'
                      />
                    </div>
                  )}
                  {searchActivities &&
                    searchActivities.map((it, idx) => (
                      <div className='col-xxl-4 col-lg-6 mb-sm-4'>
                        <ActivityBox
                          icon={list_icon}
                          activity={it}
                          key={idx}
                          disabledLesson={
                            it.creator?.member_Id != userinfo?.member_Id
                          }
                          onLessonAction={() => {
                            if (
                              it.creator?.member_Id != userinfo?.member_Id &&
                              !checkedIds.current.includes(it.activity_Id)
                            ) {
                              selectedActivity.current = it;
                              setgoBuildLesson(true);
                            } else {
                              set_cur_class('');
                              setActivityInLesson(it);
                              navigate('/build/lesson');
                            }
                          }}
                          onDelete={deleteTemplate}
                          onAdapt={(actId) => {
                            setOrgActivity(it);
                            setconfirmAdaptShow(true);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={goBuildLesson}
        content={MessageString.buildLessonMsg}
        leftTitle={'no'}
        rightTitle={'yes'}
        onDiscard={() => {
          if (selectedActivity.current) {
            set_cur_class('');
            checkedIds.current.push(selectedActivity.current.activity_Id);
            setActivityInLesson(selectedActivity.current);
            navigate('/build/lesson');
          }
        }}
        onOk={() => setgoBuildLesson(false)}
      />
      <ConfirmModal
        content={deleteMsg}
        show={deleteShow}
        leftTitle={'no'}
        rightTitle={'yes'}
        onDiscard={() => {
          onDeleteActivity(deleteId.current, {
            onSuccess: () => {
              search();
              setdeleteShow(false);
            },
          });
        }}
        onOk={() => {
          setdeleteShow(false);
        }}
      />
    </MainLayout>
  );
};
