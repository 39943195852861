import { useState } from 'react';
import man_icon from '../assets/images/icons/man_circle_icon.svg';
import profile_img from '../assets/images/images/profile.png';
import share_icon from '../assets/images/icons/share_sq.svg';
import upload_icon from '../assets/images/icons/upload_icon.svg';
import user_plus from '../assets/images/icons/user_plus.svg';
import heart_icon from '../assets/images/icons/black_heart.svg';
import comment_icon from '../assets/images/icons/black_comment.svg';
import { useNavigate } from 'react-router-dom';
import { ActivityItem } from '../components/ActivityItem';

import list_icon from '../assets/images/images/list.png';
import { CommunityLayout } from '../components/Layouts/CommunityLayout';
import { GoBackModal } from '../components/modals/GoBackModal';
export const Community = () => {
  const navigate = useNavigate();
  const [inviteModal, setinviteModal] = useState<boolean>(false);
  const [leaveModal, setleaveModal] = useState<boolean>(false);
  return (
    <CommunityLayout title='2nd Grade Teachers'>
      <>
        <div>
          <button
            className='px-5 py-1 bg-body border_select rounded-3 my-2'
            onClick={() => {
              navigate('/community/sharefiles');
            }}
          >
            view group files
          </button>
        </div>
        <div className='member_box box_shadow d-flex'>
          <div className='profile_box'>
            <img src={profile_img} alt='' width={57} />
          </div>
          <div>
            <h4>Penelope Rhodes</h4>
            <p className='text-muted'>What’s on your mind? (Type here…)</p>
            <div className='d-flex'>
              <div>
                <img src={share_icon} alt='' height={19} />
                <span className='text-muted fs-6 ms-1'>share adaptation</span>
              </div>
              <div className='ms-md-4'>
                <img src={upload_icon} alt='' height={19} />
                <span className='text-muted fs-6 ms-1'>upload file</span>
              </div>
              <div className='ms-md-4'>
                <img src={user_plus} alt='' height={19} />
                <span className='text-muted fs-6 ms-1'>tag friends</span>
              </div>
            </div>
          </div>
        </div>
        <div className='member_box box_shadow d-flex'>
          <div className='profile_box'>
            <img src={man_icon} alt='' width={57} />
          </div>
          <div>
            <h4>
              John Appleseed{' '}
              <span className='ms-4 text-muted fs-6'>2h ago</span>
            </h4>
            <p className=''>John Appleseed shared a lesson adaptation</p>
            <ActivityItem
              kindClass='default'
              icon={list_icon}
              title={`Think - Pair - Share`}
              prep={`05 m`}
              classTime={`15 m`}
              description={`Students respond to a prompt individually, then pair up to share and discuss their thoughts further.`}
            />
            <div className='d-flex mt-3'>
              <div>
                <img src={heart_icon} alt='' height={19} />
                <span className='text_info fs-6 ms-2'>28</span>
              </div>
              <div className='ms-4'>
                <img src={comment_icon} alt='' height={19} />
                <span className='text_info fs-6 ms-2'>1</span>
              </div>
              <div className='ms-4'>
                <img src={share_icon} alt='' height={19} />
                <span className='text_info fs-6 ms-2'>0</span>
              </div>
            </div>
          </div>
        </div>
        <div className='member_box box_shadow d-flex'>
          <div className='profile_box'>
            <img src={man_icon} alt='' width={57} />
          </div>
          <div>
            <h4>
              Jane Doe <span className='ms-4 text-muted fs-6'>2h ago</span>
            </h4>
            <p>Thanks for sharing, John! My class loved this activity!</p>
          </div>
        </div>
      </>
      <GoBackModal
        show={true}
        onOk={() => {
          navigate('/dashboard');
        }}
      />
    </CommunityLayout>
  );
};
