import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import close from '../../assets/images/icons/red_close.svg';
import { MessageString } from '../../models/defaults';
interface Props {
  show: boolean;
  onOk: () => void;
  onDiscard: () => void;
}
export const ConfirmDraftModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className='dashboard_video'
      centered={true}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          warning!
          <img
            src={close}
            alt=''
            
            onClick={() => {
              props.onOk();
            }}
            className='closeBtn d-none'
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        <Container>
          <div className='my-3'>
            <p className='text-center fs-5'>{MessageString.draftText}</p>
          </div>

          <div className='d-flex justify-content-evenly pt-3'>
            <button
              className='bg_warn rounded_3 py-2 px-4 fs-4'
              onClick={() => props.onOk()}
            >
              no
            </button>
            <button
              className=' border_warn bg-white rounded_3 py-2 px-4 fs-4'
              onClick={() => props.onDiscard()}
            >
              yes
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
