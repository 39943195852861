import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../components/Layouts/MainLayout';
import red_plus_icon from '../assets/images/icons/red_plus.svg';
import back_btn from '../assets/images/images/back_arrow@2x.png';
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import InputBox from '../components/InputBox';
import InputFile from '../components/InputFile';
import {
  ageLevel,
  classSizeArry,
  energyLevelArry,
  LangFocus,
  lessonStages,
  MessageString,
  proficiencyLevel,
  activityTypeArry,
  Skills,
  SpecStr,
  userLevel,
  timeArry,
  ideaKinds,
} from '../models/defaults';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { curActivity, curClass, UserInfo } from '../store';
import {
  useAttachFiles,
  useCreateDoc,
  useSaveActivity,
} from '../services/Query';
import { VerifyUserModal } from '../components/modals/VerifyUserModal';
import { Activity, ActivityValues } from '../models';
import ReactLoading from 'react-loading';
import Iframe from 'react-iframe';
import { ConfirmDraftModal } from '../components/modals/ConfirmDraftModal';
import { ConfirmModal } from '../components/modals/ConfirmModal';
import { WaitModal } from '../components/modals/WaitModal';

import HttpService from '../services/HttpServices';
import InstructEdit from '../components/InstrunctEdit';

let gInstrsValus: string[] = [];
/**
 *
 * template build page : /build/template
 */

export const BuildTemplate = () => {
  const [cActivity, setCurActivity] = useRecoilState(curActivity);
  const navigate = useNavigate();
  const [uploadSize, setUploadSize] = useState<number>(0);
  const [InstructionSize, setInstructionSize] = useState<number>(0);
  const [PrepareSize, setPrepareSize] = useState<number>(0);
  const [validate, setValidate] = useState(false);
  const [checkDraft, setcheckDraft] = useState(false);
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const [oInstruct, setoInstruct] = useState('');
  const [oPrepare, setoPrepare] = useState('');

  const [confirm, setConfirm] = useState(false);
  const [checkModal, setcheckModal] = useState(false);
  const [saveData, setSaveData] = useState<any>({});
  const [removeFileInput, setremoveFileInput] = useState<number[]>([]);
  const isDraft = useRef<boolean>(false);
  const [isEmptyDoc, setisEmptyDoc] = useState<boolean>(true);
  const [cur_class, set_cur_class] = useRecoilState(curClass);
  const g_UploadFiles = useRef<(File | null)[]>([]);
  const fileIds = useRef<string[]>([]);
  const draftEl = useRef<HTMLButtonElement>(null);

  const {
    isLoading: isCreatDoc,
    data: docUrl,
    refetch: getNewDoc,
  } = useCreateDoc(userinfo?.member_Id);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ActivityValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    gInstrsValus = [];
    if (cActivity === undefined) {
      getNewDoc();
      if (userinfo && userinfo.member_Type !== userLevel.contributor) {
        setValue('isPrivate', true);
      } else {
        setValue('isPrivate', false);
      }
    } else {
      setValue('activity_name', cActivity.activity_Name);
      setValue('activity_desc', cActivity.activity_Desc);

      setValue('activity_PrepTime', cActivity.activity_PrepTime);

      setValue('activity_UseTime', cActivity.activity_UseTime);
      setValue('isPrivate', cActivity.isPrivate ?? false);

      setafterChangable(cActivity.activity_AfterChangable);

      setactivityTypes(cActivity.activity_Type);
      // const prepares = cActivity.activity_Preparation
      //   .split(SpecStr.split_1)
      //   .filter((it) => it !== '');
      // setoPrepare(prepares[0] ?? '');

      // const files = cActivity.myFiles;
      // if (files.length > 1) {
      //   const size = files.length - 1;
      //   setUploadSize(size);
      // }
    }
  }, [cActivity, userinfo]);

  // useEffect(() => {
  //   if (userinfo && userinfo.member_Type !== userLevel.contributor) {
  //     setcheckModal(true);
  //   }
  // }, [userinfo]);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 70) {
        draftEl.current?.classList.add('sticky');
      } else {
        draftEl.current?.classList.remove('sticky');
      }
    };
    const synUser = async () => {
      if (userinfo) {
        var user = await HttpService.syncUserInfo(userinfo?.member_Id);
        setUserinfo(user);
      }
    };
    window.addEventListener('scroll', onScroll);
    synUser();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const [materialTypes, setmaterialTypes] = useState<string[]>([
    'printer paper',
    'whiteboard',
    'smartphones',
    'lined notebooks',
    'TV or projector',
    'computer/tablet',
    'pencils',
    'cardstock paper',
    'headphones',
    'pens',
    'glue',
    'other',
    'scissors',
    'tape',
  ]);

  const [materialTypeValue, setmaterialTypeValue] = useState<string[]>([]);
  const onSetMaterialTypes = (val: string) => {
    if (materialTypeValue.includes(val)) {
      setmaterialTypeValue((prev) => {
        const result: string[] = [];
        for (const it of prev) {
          if (it !== val) result.push(it);
        }
        return result;
      });
    } else {
      setmaterialTypeValue((prev) => [...prev, val]);
    }
  };

  const [ideaKindValue, setideaKindValue] = useState<string[]>([]);
  const onSetIdeaKind = (val: string) => {
    if (ideaKindValue.includes(val)) {
      setideaKindValue((prev) => {
        const result: string[] = [];
        for (const it of prev) {
          if (it !== val) result.push(it);
        }
        return result;
      });
    } else {
      setideaKindValue((prev) => [...prev, val]);
    }
  };
  const [phyKind, setphyKind] = useState('');
  const [skillFocusVal, setskillFocusVal] = useState<string[]>([]);
  const onSkillFocus = (val: string) => {
    if (skillFocusVal.includes(val)) {
      setskillFocusVal((prev) => {
        const result: string[] = [];
        for (const it of prev) {
          if (it !== val) result.push(it);
        }
        return result;
      });
    } else {
      setskillFocusVal((prev) => [...prev, val]);
    }
  };

  const [activityTypes, setactivityTypes] = useState<string>('');
  const onSetActivityTypes = (val: string) => {
    setactivityTypes(val);
  };
  const [LangeFocusVal, setLangeFocus] = useState<string[]>([]);
  const onSetLangFocus = (val: string) => {
    if (LangeFocusVal.includes(val)) {
      setLangeFocus((prev) => {
        const result: string[] = [];
        for (const it of prev) {
          if (it !== val) result.push(it);
        }
        return result;
      });
    } else {
      setLangeFocus((prev) => [...prev, val]);
    }
  };
  const renderFileInput = () => {
    const elems: React.ReactNode[] = [];
    let label = 2;
    for (let i = 0; i < uploadSize; i++) {
      const removed = removeFileInput.includes(i + 1);

      elems.push(
        <Form.Group className={`mb-1 ${removed ? 'd-none' : ''} `}>
          <Form.Label>file {label}</Form.Label>
          <InputFile
            nIndex={i + 1}
            type={'image/*, .pdf, .doc, .docx, .ppt, .pptx'}
            onSetFile={onUpdateUploadFile}
            onRemove={(idx, oId) => {
              if (g_UploadFiles.current.length > idx) {
                g_UploadFiles.current[idx] = null;
              }
              setremoveFileInput((prev) => [...prev, idx]);
              if (oId) {
                if (!fileIds.current.includes(oId)) {
                  fileIds.current.push(oId);
                }
              }
            }}
            oId={
              cActivity && cActivity._attachFiles && cActivity._attachFiles.length > i + 1
                ? cActivity?._attachFiles[i + 1].file_Name
                : undefined
            }
            fileName={
              cActivity && cActivity._attachFiles && cActivity._attachFiles.length > i + 1
                ? cActivity._attachFiles[i + 1].file_Name
                : ''
            }
          />
        </Form.Group>
      );
      if (!removed) {
      }
      label++;
    }
    return elems;
  };
  const [lessonStageVal, setlessonStageVal] = useState<string[]>([]);
  const onLessonStage = (val: string) => {
    if (lessonStageVal.includes(val)) {
      setlessonStageVal(() => {
        const result: string[] = [];
        for (const it of lessonStageVal) {
          if (it !== val) result.push(it);
        }
        return result;
      });
    } else {
      setlessonStageVal((prev) => [...prev, val]);
    }
  };
  const renderIdeaKinds = () => {
    const elements: ReactNode[] = [];
    ideaKinds.forEach((it, idx) => {
      elements.push(
        <div
          className={`${idx % 2 === 0 ? 'px-md-4 col-md-4' : 'col-md-8'}`}
          key={idx}
        >
          <Form.Check
            type="checkbox"
            label={it}
            value={it}
            checked={ideaKindValue.includes(it)}
            onChange={() => onSetIdeaKind(it)}
          />
        </div>
      );
    });
    return elements;
  };

  const renderMaterialTypes = () => {
    const elements: ReactNode[] = [];
    materialTypes.forEach((it, idx) => {
      elements.push(
        <div className={`col-md-4 ${idx % 3 === 0 ? 'px-md-4' : ''}`} key={idx}>
          <Form.Check
            type="checkbox"
            label={it}
            value={it}
            checked={materialTypeValue.includes(it)}
            onChange={() => onSetMaterialTypes(it)}
          />
        </div>
      );
    });
    const lpos = materialTypes.length;
    materialTypeValue
      .filter((it) => !materialTypes.includes(it) && it !== '')
      .forEach((it, idx) => {
        elements.push(
          <div
            className={`col-md-4 ${lpos + (idx % 3) === 0 ? 'px-md-4' : ''}`}
            key={idx}
          >
            <Form.Check
              type="checkbox"
              label={it}
              value={it}
              checked={materialTypeValue.includes(it)}
              onChange={() => onSetMaterialTypes(it)}
            />
          </div>
        );
      });
    return elements;
  };
  const [RemovedInstructsIds, setRemovedInstructsIds] = useState<number[]>([]);
  const [RemovedPrepareIds, setRemovedPrepareIds] = useState<number[]>([]);
  const prepareVals = useRef<string[]>([]);
  const onInstRemove = (idx: number) => {
    setRemovedInstructsIds((prev) => [...prev, idx]);
  };
  const isEmpty = (str: string | undefined) => !str || /^\s*$/.test(str);
  const onPrepareRemove = (idx: number) => {
    setRemovedPrepareIds((prev) => [...prev, idx]);
  };
  const onUpdateUploadFile = (
    idx: number,
    vfile: File,
    oId: string | undefined
  ) => {
    if (g_UploadFiles.current.length > idx) {
      g_UploadFiles.current[idx] = vfile;
    } else {
      g_UploadFiles.current.push(vfile);
    }
    if (oId) {
      if (!fileIds.current.includes(oId)) {
        fileIds.current.push(oId);
      }
    }
  };
  const onInstUpdate = (val: string, idx: number) => {
    if (gInstrsValus.length > idx) {
      gInstrsValus[idx] = val;
    } else {
      gInstrsValus.push(val);
    }
  };
  const onPrepareUpdate = (val: string, idx: number) => {
    if (prepareVals.current.length > idx) {
      prepareVals.current[idx] = val;
    } else {
      prepareVals.current.push(val);
    }
  };
  const renderInstructionEl = () => {
    const elms: React.ReactNode[] = [];
    let vIndex = 1;
    for (let i = 0; i < InstructionSize; i++) {
      if (RemovedInstructsIds.includes(i)) continue;
      vIndex++;
      elms.push(
        <InputBox
          order={vIndex}
          nIndex={i}
          nValue={gInstrsValus[i]}
          removable={true}
          onRemove={onInstRemove}
          onChange={onInstUpdate}
        />
      );
    }
    return elms;
  };
  const renderPrepareEl = () => {
    const elms: React.ReactNode[] = [];
    let vIndex = 1;
    for (let i = 0; i < PrepareSize; i++) {
      if (RemovedPrepareIds.includes(i)) continue;
      vIndex++;
      elms.push(
        <InputBox
          order={vIndex}
          nIndex={i}
          nValue={prepareVals.current[i]}
          removable={true}
          onRemove={onPrepareRemove}
          onChange={onPrepareUpdate}
        />
      );
    }
    return elms;
  };
  const [afterChangable, setafterChangable] = useState(false);
  const { isLoading: isSaving, mutate: onSaveInfo } = useSaveActivity();
  const { isLoading: isUploading, mutate: onAttachFiles } = useAttachFiles();
  const onSuccessPass = (_act: Activity) => {
    if (!userinfo?.myFolders) {
      navigate('/dashboard');
    }
    if (isDraft.current) {
      const draftF = userinfo?.myFolders
        .filter((it) => it.folder_name.indexOf(SpecStr.draft) === 0)
        .at(0);
      if (draftF) {
        navigate(`/folderdetail/${draftF.folder_Id}`);
      } else {
        setCurActivity(_act);
        navigate('/build/template/detail/1');
      }
    } else {
      setCurActivity(_act);
      navigate('/build/template/detail/1');
    }
  };
  const onAfterError = () => {
    alert('an error occurred.');
  };
  const afterSave = (fData: any) => {
    if (!userinfo) return;
    const formData = new FormData();
    if (g_UploadFiles.current.length > 0) {
      let i_pos = 0;
      for (const it of g_UploadFiles.current) {
        if (it != null) {
          formData.append(`uploadFile${i_pos}`, it);
          i_pos++;
        }
      }

      onAttachFiles(formData, {
        onSuccess: (files) => {
          // fData.attachFiles = files.join(',');
          // onSaveInfo(
          //   {
          //     memberId: userinfo.member_Id,
          //     formData: JSON.stringify(fData),
          //   },
          //   {
          //     onSuccess: onSuccessPass,
          //     onError: onAfterError,
          //   }
          // );
        },
        onError: () => {
          alert('an error occurred while uploading files.');
        },
      });
    } else {
      // onSaveInfo(
      //   { memberId: userinfo.member_Id, formData: JSON.stringify(fData) },
      //   {
      //     onSuccess: onSuccessPass,
      //     onError: onAfterError,
      //   }
      // );
    }
  };
  const onSaveData = (data: ActivityValues) => {
    if (!userinfo) return;
    const insts: string[] = [];
    const prepares: string[] = [];
    gInstrsValus.forEach((it, idx) => {
      if (!RemovedInstructsIds.includes(idx)) insts.push(it);
    });
    prepareVals.current.forEach((it, idx) => {
      if (!RemovedPrepareIds.includes(idx)) prepares.push(it);
    });
    if (
      !isDraft.current &&
      (activityTypes === '' ||
        (oInstruct === '' && gInstrsValus.length === 0) ||
        (oPrepare === '' && prepareVals.current.length === 0) ||
        materialTypeValue.length === 0 ||
        ideaKindValue.length === 0 ||
        LangeFocusVal.length === 0 ||
        skillFocusVal.length === 0 ||
        lessonStageVal.length === 0)
    ) {
      setValidate(true);
      return;
    }

    const fdata = {
      activity_ClassSizeHigh: data.activity_ClassSizeHigh ?? '',
      activity_ClassSizeLow: data.activity_ClassSizeLow ?? '',
      activity_EnergyHigh: data.activity_EnergyHigh ?? '',
      activity_EnergyLow: data.activity_EnergyLow ?? '',
      activity_PrepTime: data.activity_PrepTime ?? '',
      activity_ProficiencyHigh: data.activity_ProficiencyHigh ?? '',
      activity_ProficiencyLow: data.activity_ProficiencyLow ?? '',
      activity_StudentAgeHigh: data.activity_StudentAgeHigh ?? '',
      activity_StudentAgeLow: data.activity_StudentAgeLow ?? '',
      activity_UseTime: data.activity_UseTime ?? '',
      activity_desc: data.activity_desc ?? '',
      activity_name: data.activity_name,
      activity_note: data.activity_note ?? '',
      activity_Preparation:
        oPrepare + SpecStr.split_1 + prepares.join(SpecStr.split_1),
      activity_AfterChangable: afterChangable ? 'true' : 'false',
      activity_Types: activityTypes ?? '',
      activity_Instructions:
        oInstruct + SpecStr.split_1 + insts.join(SpecStr.split_1),
      activity_MaterialTypes: materialTypeValue.join(SpecStr.split_common),
      Activity_IdeaTypes: ideaKindValue.join(SpecStr.split_common),
      activity_LanguageFocus: LangeFocusVal.join(SpecStr.split_common),
      activity_SkillFocus: skillFocusVal.join(SpecStr.split_common),
      activity_LessonStage: lessonStageVal.join(SpecStr.split_common),
      activity_Id: cActivity?.activity_Id ?? '',
      docUrl: cActivity?.activity_DocUrl ?? docUrl,
      activity_EmptyDoc: !isEmptyDoc,
      isDraft: isDraft.current,
      fileIds: fileIds.current.join(SpecStr.split_common),
      isPrivate: data.isPrivate,
      classId: cur_class,
      attachFiles: '',
    };
    setSaveData(fdata);
    if (isDraft.current) {
      setcheckDraft(true);
      return;
    }
    afterSave(fdata);
  };
  return (
    <MainLayout>
      <div className="template_wrap">
        <div className="header">
          <img
            src={back_btn}
            width={125}
            className="cursor ms-2"
            alt=""
            onClick={() => {
              setConfirm(true);
            }}
          />
        </div>
        <div className="header text-center">
          <h4>your ideas, brought to life</h4>
          <p className="description">
            they are just the ones your students need
          </p>
        </div>
        <button
          type="button"
          name="draft"
          className="publish_btn  py-2 mx-4 draft"
          ref={draftEl}
          disabled={
            isSaving ||
            isUploading ||
            isEmpty(cActivity?.activity_DocUrl ?? docUrl)
          }
          onClick={async () => {
            const flag = await trigger('activity_name', { shouldFocus: true });
            if (flag) {
              const values = watch();
              isDraft.current = true;
              onSaveData(values);
            }
          }}
        >
          {(isSaving || isUploading) && isDraft.current && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              className="me-2"
              role="status"
              aria-hidden="true"
            />
          )}
          save draft
        </button>
        <div className="body">
          <form onSubmit={handleSubmit(onSaveData)}>
            <h4>select an activity type</h4>
            <div className="description">
              select the activity type that best describes your idea
            </div>
            <div className="types_options my-2">
              <div className="row mx-0">
                {activityTypeArry.map((it, idx) => (
                  <div
                    className={`col-md-6 mt-2 cursor ${
                      idx % 2 === 0 ? 'text-end' : ''
                    }`}
                    key={idx}
                  >
                    <span
                      className={`type_cell ${
                        activityTypes === it ? 'on' : ''
                      }`}
                      onClick={() => onSetActivityTypes(it)}
                    >
                      {it}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <h4 className="mt-3">input activity template details</h4>
            <div className="description">
              fill in your activity instructions, then answer a few simple
              questions
            </div>
            <div className="details">
              <Container className="py-3">
                <Row className="sg-row px-md-4 mx-0">
                  <div className="px-md-4 col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        name your activity <br />
                        <span className="desc">
                          give a brief title to this template
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register('activity_name', { required: true })}
                      />
                      {errors.activity_name && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0">
                  <div className="px-md-4 col-md-10">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        description <br />
                        <span className="desc">
                          This should be a very short summary of the main thing
                          students do
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="example: “Students attempt to recall words after they have been hidden or erased”"
                        {...register('activity_desc', { required: true })}
                      />
                      {errors.activity_desc && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0">
                  <div className="px-md-4 col-md-10">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        preparation <br />
                        <span
                          className={`${
                            validate && oPrepare === '' ? 'text-danger' : 'desc'
                          }`}
                        >
                          what needs to be prepared before class
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                </Row>
                <div className="instructions container px-md-4 ms-md-4">
                  <InputBox
                    order={1}
                    nIndex={0}
                    removable={false}
                    nValue={oPrepare ?? ''}
                    onRemove={onPrepareRemove}
                    onChange={(val) => setoPrepare(val)}
                  />
                  {renderPrepareEl()}
                  {PrepareSize - RemovedPrepareIds.length < 49 && (
                    <div className="text-center add_wrap">
                      <img
                        src={red_plus_icon}
                        className="cursor"
                        alt=""
                        onClick={() => {
                          setPrepareSize((prev) => ++prev);
                        }}
                      />
                    </div>
                  )}
                </div>
                <Row className="sg-row px-md-4 mx-0">
                  <div className="px-md-4 col-md-10">
                    <Form.Group>
                      <Form.Label>
                        instructions <br />
                        <span
                          className={`${
                            validate && oInstruct === ''
                              ? 'text-danger'
                              : 'desc'
                          }`}
                        >
                          type out each step of the activity
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                </Row>
                <div className="instructions container px-md-4 ms-md-4">
                  <InstructEdit
                    order={1}
                    nIndex={0}
                    removable={false}
                    nValue={oInstruct ?? ''}
                    count={1}
                    onRemove={onInstRemove}
                    onChange={(val) => setoInstruct(val)}
                  />
                  {renderInstructionEl()}
                  {InstructionSize - RemovedInstructsIds.length < 49 && (
                    <div className="text-center add_wrap">
                      <img
                        src={red_plus_icon}
                        className="cursor"
                        alt=""
                        onClick={() => {
                          setInstructionSize((prev) => ++prev);
                        }}
                      />
                    </div>
                  )}
                </div>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-10">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        additional notes <br />
                        <span className="desc">
                          here you can type out additional information or
                          suggestions
                        </span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        maxLength={5000}
                        {...register('activity_note')}
                      />
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-10">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        What physical materials are needed for this activity?
                        <br />
                        <span
                          className={`${
                            validate && materialTypeValue.length === 0
                              ? 'text-danger'
                              : 'desc'
                          }`}
                        >
                          check the boxes next to required materials
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0">
                  <React.Fragment>{renderMaterialTypes()}</React.Fragment>
                  <div className="col-md-4 mt-1">
                    <input
                      type="text"
                      className="form-control round-border"
                      value={phyKind}
                      onChange={(e) => setphyKind(e.target.value)}
                    />
                    <div className="text-center mt-1">
                      <img
                        src={red_plus_icon}
                        className="cursor"
                        alt=""
                        onClick={() => {
                          if (phyKind !== '') {
                            if (!materialTypes.includes(phyKind)) {
                              setmaterialTypes((prev) => [...prev, phyKind]);
                            }
                            setphyKind('');
                          }
                        }}
                      />
                    </div>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        do you need to upload any files?
                        <br />
                        <span className="desc">
                          .pdf, .docx, .ppt or image files can be uploaded here
                        </span>
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>file 1</Form.Label>
                      <InputFile
                        nIndex={0}
                        type={'image/*, .pdf, .doc, .docx, .ppt, .pptx'}
                        onSetFile={onUpdateUploadFile}
                        oId={
                          cActivity &&
                          cActivity._attachFiles &&
                          cActivity._attachFiles.length
                            ? cActivity?._attachFiles[0].file_Name
                            : undefined
                        }
                        fileName={
                          cActivity &&
                          cActivity._attachFiles &&
                          cActivity._attachFiles.length
                            ? cActivity?._attachFiles[0].file_Name
                            : ''
                        }
                      />
                    </Form.Group>
                    <React.Fragment>{renderFileInput()}</React.Fragment>
                    <div className="text-center my-2">
                      <img
                        src={red_plus_icon}
                        className="cursor"
                        alt=""
                        onClick={() => setUploadSize((prev) => ++prev)}
                      />
                    </div>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>material template</Form.Label>
                      <Form.Check
                        type="checkbox"
                        label={'add a material template document'}
                        value={1}
                        checked={isEmptyDoc}
                        onChange={() => setisEmptyDoc((prev) => !prev)}
                      />
                    </Form.Group>
                  </div>
                </Row>
                <Row
                  className={`sg-row px-md-4 mx-0 ${
                    isEmptyDoc ? '' : 'd-none'
                  }`}
                >
                  <div className="col">
                    {cActivity === undefined &&
                      (isCreatDoc ? (
                        <div className="w-100 text-center py-4 ">
                          <ReactLoading
                            type="spinningBubbles"
                            className="mx-auto my-auto"
                            height={'80px'}
                            width={'80px'}
                            color="#666666"
                          />
                        </div>
                      ) : (
                        <Iframe
                          url={`https://docs.google.com/document/d/${docUrl}/edit`}
                          width="100%"
                          height="700px"
                          id="myId"
                          className="myClassname"
                          position="relative"
                        />
                      ))}
                    {cActivity && (
                      <Iframe
                        url={`https://docs.google.com/document/d/${cActivity.activity_DocUrl}/edit`}
                        width="100%"
                        height="700px"
                        id="myId"
                        className="myClassname"
                        position="relative"
                      />
                    )}
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        about how much time will this activity take to prepare?
                        <br />
                        <span className="desc">
                          estimate as best you can based upon your experience
                        </span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register('activity_PrepTime', { required: true })}
                      >
                        <option value=""></option>
                        {timeArry.map((it, idx) => (
                          <option value={it} key={idx}>
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_PrepTime && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        about how much class time will this activity use?
                        <br />
                        <span className="desc">
                          include time for material distribution in your
                          estimate
                        </span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register('activity_UseTime', { required: true })}
                      >
                        <option value=""></option>
                        {timeArry.map((it, idx) => (
                          <option value={it} key={idx}>
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_UseTime && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-10">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        does your activity idea include...
                        <br />
                        <span
                          className={` ${
                            validate && ideaKindValue.length === 0
                              ? 'text-danger'
                              : 'desc'
                          }`}
                        >
                          check all that apply
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0">
                  <React.Fragment>{renderIdeaKinds()}</React.Fragment>
                </Row>
                <Row className="sg-row px-md-4 mx-0 mt-3">
                  <div className="px-md-4 col-md-12">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        After initial use, can this activity be done again
                        without changing or replacing materials?
                        <br />
                        <span className="desc">please choose one</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                </Row>
                <Row className="sg-row px-md-4 mx-0">
                  <div className={`col-md-12 px-md-4`}>
                    <Form.Check
                      type="radio"
                      name="after_change"
                      checked={afterChangable}
                      onChange={() => setafterChangable(true)}
                      label={`yes`}
                      className="mb-2"
                    />
                  </div>
                  <div className={`col-md-12 px-md-4`}>
                    <Form.Check
                      type="radio"
                      name="after_change"
                      label={`no`}
                      onChange={() => setafterChangable(false)}
                      checked={!afterChangable}
                    />
                  </div>
                </Row>
              </Container>
            </div>
            <h4 className="mt-3">categorize your template</h4>
            <div className="description">
              select all that apply to your idea - at least one per category
              heading
            </div>
            <Container>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      language focus
                      <br />
                      <span
                        className={` ${
                          validate && LangeFocusVal.length === 0
                            ? 'text-danger'
                            : 'desc'
                        }`}
                      >
                        compatible lesson objective types
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
              </Row>
              <div className="row">
                {LangFocus.map((it, idx) => (
                  <div
                    className={`cursor ${
                      idx % 2 === 0 ? 'col-md-6 col-lg-5 text-end' : 'col-md-4'
                    } mb-2`}
                    key={idx}
                  >
                    <span
                      className={`type_cell ${
                        LangeFocusVal.includes(it) ? 'on' : ''
                      }`}
                      onClick={() => onSetLangFocus(it)}
                    >
                      {it}
                    </span>
                  </div>
                ))}
              </div>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      skill focus
                      <br />
                      <span
                        className={` ${
                          validate && skillFocusVal.length === 0
                            ? 'text-danger'
                            : 'desc'
                        }`}
                      >
                        which skills can this template be configured to address?
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
              </Row>
              <div className="row">
                {Skills.map((it, idx) => (
                  <div
                    className={`cursor ${
                      idx % 2 === 0 ? 'col-md-6 col-lg-5 text-end' : 'col-md-4'
                    } mb-2`}
                    key={idx}
                  >
                    <span
                      className={`type_cell ${
                        skillFocusVal.includes(it) ? 'on' : ''
                      }`}
                      onClick={() => onSkillFocus(it)}
                    >
                      {it}
                    </span>
                  </div>
                ))}
              </div>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      lesson stage
                      <br />
                      <span
                        className={` ${
                          validate && lessonStageVal.length === 0
                            ? 'text-danger'
                            : 'desc'
                        }`}
                      >
                        which parts of a lesson could this template be
                        configured for?
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
              </Row>
              <div className="row mt-2">
                {lessonStages.map((it, idx) => (
                  <div
                    className={`cursor ${
                      idx % 2 === 0 ? 'col-md-6 col-lg-5 text-end' : 'col-md-4'
                    } mb-2`}
                    key={idx}
                  >
                    <span
                      className={`type_cell ${
                        lessonStageVal.includes(it) ? 'on' : ''
                      }`}
                      onClick={() => onLessonStage(it)}
                    >
                      {it}
                    </span>
                  </div>
                ))}
              </div>
            </Container>
            <h4 className="mt-3">
              what kind of class is this activity best for?
            </h4>
            <div className="description">set ideal class parameters</div>
            <Container>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      class size
                      <br />
                      <span className="desc">
                        what class sizes are feasible?
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      student age
                      <br />
                      <span className="desc">
                        what age range is this best for?
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
              </Row>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>smallest</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_ClassSizeLow', {
                          required: true,
                        })}
                      >
                        <option value=""></option>
                        {classSizeArry.map((it, idx) => (
                          <option value={it} key={idx}>
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_ClassSizeLow && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>largest</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_ClassSizeHigh', {
                          required: true,
                        })}
                      >
                        <option value=""></option>
                        {classSizeArry.map((it, idx) => (
                          <option
                            value={it}
                            key={idx}
                            disabled={
                              classSizeArry.findIndex(
                                (it) => it === watch('activity_ClassSizeLow')
                              ) > idx
                            }
                          >
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_ClassSizeHigh && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                </div>
                <div className="px-md-4 col-md-6">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>youngest</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_StudentAgeLow', {
                          required: true,
                        })}
                      >
                        <option value=""></option>
                        {ageLevel.map((it, idx) => (
                          <option value={it} key={idx}>
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_StudentAgeLow && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>oldest</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_StudentAgeHigh', {
                          required: true,
                        })}
                      >
                        <option value=""></option>
                        {ageLevel.map((it, idx) => (
                          <option
                            value={it}
                            key={idx}
                            disabled={
                              ageLevel.findIndex(
                                (it) => it === watch('activity_StudentAgeLow')
                              ) > idx
                            }
                          >
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_StudentAgeHigh && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                </div>
              </Row>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      proficiency levels
                      <br />
                      <span className="desc">aligned with the CEFR</span>
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="px-md-4 col-md-6">
                  <Form.Group className="mb-1">
                    <Form.Label>
                      energy level
                      <br />
                      <span className="desc">
                        every class has its own capacity
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
              </Row>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <div className="px-md-4 col-md-6">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>as low as</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_ProficiencyLow', {
                          required: true,
                        })}
                      >
                        <option></option>
                        {proficiencyLevel.map((it, idx) => (
                          <option value={it} key={idx}>
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_ProficiencyLow && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>as high as</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_ProficiencyHigh', {
                          required: true,
                        })}
                      >
                        <option></option>
                        {proficiencyLevel.map((it, idx) => (
                          <option
                            value={it}
                            key={idx}
                            disabled={
                              proficiencyLevel.findIndex(
                                (it) => it === watch('activity_ProficiencyLow')
                              ) > idx
                            }
                          >
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </InputGroup>
                </div>
                <div className="px-md-4 col-md-6">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>as low as</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_EnergyLow', { required: true })}
                      >
                        <option></option>
                        {energyLevelArry.map((it, idx) => (
                          <option value={it} key={idx}>
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_EnergyLow && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>as high as</InputGroup.Text>
                    <Form.Group>
                      <Form.Select
                        {...register('activity_EnergyHigh', { required: true })}
                      >
                        <option></option>
                        {energyLevelArry.map((it, idx) => (
                          <option
                            value={it}
                            key={idx}
                            disabled={
                              energyLevelArry.findIndex(
                                (it) => it === watch('activity_EnergyLow')
                              ) > idx
                            }
                          >
                            {it}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.activity_EnergyHigh && (
                        <Form.Text className="text-danger">
                          {MessageString.required}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </InputGroup>
                </div>
              </Row>
              <Row className="sg-row px-md-4 mx-0 mt-3">
                <Col className="px-md-4">
                  <Form.Check
                    type="switch"
                    className="fw-bold"
                    disabled={
                      userinfo && userinfo.member_Type !== userLevel.contributor
                    }
                    size={40}
                    label="keep activity private"
                    {...register('isPrivate')}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-4">
                <button
                  type="submit"
                  name="publish"
                  className="publish_btn  py-2"
                  disabled={isSaving || isUploading}
                  onClick={() => {
                    isDraft.current = false;
                  }}
                >
                  {(isSaving || isUploading) && !isDraft.current && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="me-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  preview & publish
                </button>
              </div>
            </Container>
          </form>
        </div>
      </div>
      <ConfirmModal
        show={confirm}
        content={MessageString.discardText}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          setConfirm(false);
        }}
        onDiscard={async () => {
          navigate('/dashboard');
          setConfirm(false);
          // if (docUrl) HttpService.deleteDocument(docUrl);
        }}
      />
      <VerifyUserModal
        show={checkModal}
        onOk={() => {
          setcheckModal(false);
          navigate('/build');
        }}
      />
      <ConfirmDraftModal
        show={checkDraft}
        onOk={() => {
          setcheckDraft(false);
        }}
        onDiscard={() => {
          setcheckDraft(false);
          afterSave(saveData);
        }}
      />
      <WaitModal
        show={isSaving || isUploading}
        content={
          isDraft.current
            ? 'Saving your draft activity! Please wait!'
            : 'Preparing the Preview of your activity template! <br /> Please wait!'
        }
      />
    </MainLayout>
  );
};
