import React, { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from './MainLayout';
import { InviteOtherModal } from '../modals/InviteOtherModal';
import man_icon from '../../assets/images/icons/man_circle_icon.svg';
import discard_icon from '../../assets/images/icons/discard_icon.svg';
import white_arrow from '../../assets/images/icons/white-left-arrow.svg';

import { LeaveGroupModal } from '../modals/LeaveGroupModal';
import ReactLoading from 'react-loading';

interface Props {
  title: string;
  isFriend?: boolean;
  isFindFriend?: boolean;
}
export const CommunityLayout = (props: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const [inviteModal, setinviteModal] = useState<boolean>(false);
  const [leaveModal, setleaveModal] = useState<boolean>(false);
  return (
    <MainLayout>
      <div className='mask'>
        <ReactLoading
          type='spinningBubbles'
          className='mx-auto my-auto'
          height={'100px'}
          width={'100px'}
          color='#000000'
        />
        coming soon!
      </div>
      <div className='d-flex'>
        <div className='left_menu'>
          <h4>GROUPS</h4>
          <ul className='ps-0 ms-0'>
            <li>
              <b>Group A — 2nd Grade Teachers</b>
            </li>
            <li>
              <b>Group B — Tutor Tips</b>
            </li>
            <li>
              <b>Group C — Adapted Lesson </b>
            </li>
            <li>
              <b>Group D — Inspiration</b>
            </li>
          </ul>
          <h4>FRIENDS</h4>
          <div>
            <ul className='ps-0 ms-0'>
              <li>
                <img src={man_icon} alt='' width={24} />
                <span className='ms-1'>Jay Baxter</span>
              </li>
              <li>
                <img src={man_icon} alt='' width={24} />
                <span className='ms-1'>John Appleseed</span>
              </li>
              <li>
                <img src={man_icon} alt='' width={24} />
                <span className='ms-1'>Alex Charles</span>
              </li>
              <li>
                <img src={man_icon} alt='' width={24} />
                <span className='ms-1'>Chad Daniels</span>
              </li>
            </ul>
            <div className='text-center'>
              <button
                className='border-0 bg-body all_view'
                onClick={() => {
                  navigate('/community/friends');
                }}
              >
                view all friends
              </button>
            </div>
          </div>

          <h4>DISCORD</h4>
          <div className='ps-3 '>
            <img src={discard_icon} alt='' className='align-top' />
            <span className='ps-1 discard_txt '>
              Join Edusfere Discord Channel
            </span>
          </div>
        </div>
        <div className='dash_content'>
          <div className='header d-flex w-100 justify-content-between px-2'>
            <button
              className='back_btn border-0 bg-body'
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={white_arrow} alt='' /> back
            </button>
            <div>
              <h4 className='caption_1'>{props.title}</h4>
            </div>
            <div className='d-flex flex-column'>
              {props.isFriend && (
                <>
                  <button
                    className={`bg_sec border-0 box_shadow text-white ${
                      props.isFindFriend ? 'invisible' : ''
                    }`}
                    onClick={() => {
                      navigate('/community/find_friends');
                    }}
                  >
                    find friends
                  </button>
                </>
              )}
              {!props.isFriend && (
                <>
                  <button
                    className='bg_prim border-0 box_shadow text-white'
                    onClick={() => {
                      setinviteModal(true);
                    }}
                  >
                    invite others
                  </button>
                  <button
                    className='bg_sec border-0 box_shadow text-white mt-2'
                    onClick={() => {
                      setleaveModal(true);
                    }}
                  >
                    leave group
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='content template_list ps-0'>{props.children}</div>
        </div>
      </div>
      <InviteOtherModal
        show={inviteModal}
        onOk={() => {
          setinviteModal(false);
        }}
      />
      <LeaveGroupModal
        show={leaveModal}
        onOk={() => {
          setleaveModal(false);
        }}
      />
    </MainLayout>
  );
};
