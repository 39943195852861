import React, { useEffect, useState } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import { MessageString } from '../../models/defaults';
import { curActivity, UserInfo } from '../../store';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAdaptActivity } from '../../services/Query';
import ReactLoading from 'react-loading';
import { Activity } from '../../models';

interface Props {
  show: boolean;
  onOk: () => void;
  orgActivity: Activity | undefined;
  onAdapting: () => void;
}
export const ConfirmAdaptModal = (props: Props) => {
  const [isAdapting, setIsAdapting] = useState(false);
  const setCurActivity = useSetRecoilState(curActivity);
  const userinfo = useRecoilValue(UserInfo);
  const [step, setstep] = useState<number>(0);
  const { isLoading: isSaving, mutate: onAdapt } = useAdaptActivity();
  const [classId, setclassId] = useState<string>('');
  const onGoAdapt = async () => {
    if (isAdapting) return;
    if (userinfo && props.orgActivity) {
      // onAdapt(
      //   {
      //     memberId: userinfo.member_Id,
      //     orgActivityId: props.orgActivity.activity_Id,
      //     classId: classId,
      //   },
      //   {
      //     onSuccess: (nwTemp) => {
      //       if (nwTemp != null) {
      //         setCurActivity(nwTemp);
      //         props.onAdapting();
      //       }
      //     },
      //     onError: () => {},
      //   }
      // );
    }
  };
  useEffect(() => {
    if (props.show) {
      setIsAdapting(false);
      setclassId('');
      setstep(0);
    }
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      className='dashboard_video'
      keyboard={false}
      backdrop='static'
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        {isSaving ? (
          <div className='w-100 text-center py-4 '>
            <h5>{MessageString.adaptWait}</h5>
            <ReactLoading
              type='spinningBubbles'
              className='mx-auto my-3'
              height={'80px'}
              width={'80px'}
              color='#666666'
            />
          </div>
        ) : (
          <Container>
            {step === 0 && (
              <>
                <div className='my-3'>
                  <div className='row mx-0'>
                    <div className='col-2'>title:</div>
                    <div className='col fw-bold ps-3'>
                      {props?.orgActivity?.activity_Name}
                    </div>
                  </div>
                  <div className='row mx-0'>
                    <div className='col-2'>description:</div>
                    <div className='col ps-3'>
                      {props?.orgActivity?.activity_Desc}
                    </div>
                  </div>
                </div>
                <p className='text-center fs-5 fnt_robot'>
                  {MessageString.adaptText}
                </p>
                <div className='d-flex justify-content-evenly pt-3'>
                  <button
                    className='bg_warn rounded_3 py-2 px-4 fs-4'
                    onClick={() => props.onOk()}
                  >
                    No
                  </button>
                  <button
                    className=' border_warn bg-white rounded_3 py-2 px-4 fs-4'
                    onClick={() => {
                      setstep(1);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </>
            )}
            {step === 1 && (
              <>
                <div className='my-3'>
                  <p className='text-center fs-5 fnt_robot'>
                    {MessageString.selectClassTxt}
                  </p>
                  <div className='row mx-0'>
                    <div className='col-8 offset-2'>
                      <Form.Select
                        aria-label='Class'
                        value={classId}
                        onChange={(e) => setclassId(e.target.value)}
                      >
                        <option value=''></option>
                        {userinfo?.myClasses.map((it, idx) => (
                          <option value={it.class_Id} key={it.class_Id}>
                            {it.class_Title}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-evenly pt-3'>
                  <button
                    className='bg_warn rounded_3 py-2 px-4 fs-4'
                    onClick={() => setstep(0)}
                  >
                    back
                  </button>
                  <button
                    className=' border_warn bg-white rounded_3 py-2 px-4 fs-4'
                    disabled={classId === ''}
                    onClick={() => {
                      setstep(2);
                    }}
                  >
                    next
                  </button>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className='my-3'>
                  <div className='row mx-0'>
                    <div className='col-3'>title:</div>
                    <div className='col fw-bold ps-3'>
                      {props?.orgActivity?.activity_Name}
                    </div>
                  </div>
                  <div className='row mx-0'>
                    <div className='col-3'>description:</div>
                    <div className='col ps-3'>
                      {props?.orgActivity?.activity_Desc}
                    </div>
                  </div>
                  <div className='row mx-0'>
                    <div className='col-3'>selected class:</div>
                    <div className='col ps-3'>
                      {
                        userinfo?.myClasses.find(
                          (it) => it.class_Id === classId
                        )?.class_Title
                      }
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-evenly pt-3'>
                  <button
                    className='bg_warn rounded_3 py-2 px-4 fs-4'
                    onClick={() => setstep(1)}
                  >
                    cancel
                  </button>
                  <button
                    className=' border_warn bg-white rounded_3 py-2 px-4 fs-4'
                    disabled={classId === ''}
                    onClick={() => {
                      onGoAdapt();
                      setstep(3);
                    }}
                  >
                    adapt
                  </button>
                </div>
              </>
            )}
            {step === 3 && (
              <div className='w-100 text-center py-4 '>
                <h5>{MessageString.adaptWait}</h5>
                {isSaving && (
                  <ReactLoading
                    type='spinningBubbles'
                    className='mx-auto my-3'
                    height={'80px'}
                    width={'80px'}
                    color='#666666'
                  />
                )}
              </div>
            )}
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};
