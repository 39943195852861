import React from 'react';
import remove_icon from '../assets/images/icons/red_minus.svg';
interface Props {
  order: number;
  nIndex: number;
  removable: boolean;
  nValue: string;
  onRemove: (index: number) => void;
  onChange: (val: string, index: number) => void;
}
const InputBox = (props: Props) => {
  return (
    <div className='input_box d-flex my-2'>
      <b>{props.order}.</b>
      <textarea        
        className='px-3'
        rows={3}
        defaultValue={props.nValue}
        onChange={(e) => {
          props.onChange(e.target.value, props.nIndex);
        }}
      ></textarea>
      {props.removable && (
        <img
          src={remove_icon}
          alt=''
          onClick={() => {
            props.onRemove(props.nIndex);
          }}
        />
      )}
    </div>
  );
};
export default React.memo(InputBox);
