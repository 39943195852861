import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FooterSlider } from '../components/Layouts/Footers/FooterSlider';
import { MainLayout } from '../components/Layouts/MainLayout';
import HttpService from '../services/HttpServices';
import { useRecoilState } from 'recoil';
import { UserInfo } from '../store';
import { useGetFolder, useFolderData } from '../services/Query';
import back_btn from '../assets/images/images/back_arrow@2x.png';
/**
 *
 * Folder edit page : /build/folderedit
 */
export const FolderEdit = () => {
  const navigate = useNavigate();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const [step, setStep] = useState<number>(1);
  const prev = () => {
    setStep((st) => --st);
  };
  const next = () => {
    setStep((st) => ++st);
    console.log('next');
  };
  let { folderId } = useParams();
  const { data: folderInfo } = useGetFolder(folderId);
  const [title, settitle] = useState('');
  const [description, setdescription] = useState('');
  useEffect(() => {
    if (folderInfo) {
      settitle(folderInfo.folder_name);
      setdescription(folderInfo.folder_desc);
    }
  }, [folderInfo]);
  const { isLoading, mutate: saveFolder } = useFolderData();
  const onSave = async () => {
    if (isLoading) return;
    const data = {
      folder_name: title,
      folder_desc: description,
      ownerId: userinfo?.member_Id,
    };
    if (folderId && userinfo) {
      saveFolder(
        { memberId: folderId, formData: JSON.stringify(data) },
        {
          onSuccess: async (res) => {
            if (userinfo) {
              const user = await HttpService.getUserInfo(userinfo.member_Id);
              if (user) setUserinfo(user);
            }
            navigate('/dashboard');
          },
        }
      );
    }
  };
  return (
    <MainLayout>
      <div className='signup_wrap lesson_page'>
        <h4 className='text-center py-3 bg_selected mb-0'>
          {folderInfo ? 'update folder' : 'create a new folder'}
        </h4>
        <div className='description py-3 text-center'>
          organize your collection
        </div>
        <Container className='pt-4'>
          <div className='d-flex justify-content-between mb-4'>
            <button
              className='border-0 bg-body ms-3'
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={back_btn} alt='' width={125} />
            </button>
          </div>
          {step == 1 && (
            <Card className='ramp_card border_shadow mx-auto'>
              <Card.Header>
                <div className='d-flex justify-content-between'>
                  <div className='px-md-4 mt-3 d-flex align-items-end'>
                    <div className='cell'>
                      <h5>give this folder a title</h5>
                      <p>anything will do - you can change it later</p>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className='pb-5'>
                <Container>
                  <Row className='sg-row px-lg-4'>
                    <Col className='px-md-4'>
                      <Form.Group className='mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Folder Name'
                          value={title}
                          onChange={(e) => settitle(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer>
                <FooterSlider
                  size={2}
                  current={step}
                  prev={prev}
                  next={next}
                  isNext={title != ''}
                />
              </Card.Footer>
            </Card>
          )}
          {step == 2 && (
            <Card className='ramp_card border_shadow mx-auto'>
              <Card.Header>
                <div className='d-flex justify-content-between'>
                  <div className='px-md-4 mt-3 d-flex align-items-end'>
                    <div className='cell'>
                      <h5>write a description for your folder</h5>
                      <p>what will you put in this folder?</p>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className='pb-5'>
                <Container>
                  <Row className='sg-row px-lg-4'>
                    <Col className='px-md-4'>
                      <Form.Group className='mb-3'>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          value={description}
                          onChange={(e) => setdescription(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer>
                <FooterSlider
                  size={2}
                  current={step}
                  prev={prev}
                  next={next}
                  isNext={!isLoading}
                  isPrepare={isLoading}
                  lastText={folderInfo ? 'update' : 'create'}
                  last={onSave}
                />
              </Card.Footer>
            </Card>
          )}
        </Container>
      </div>
    </MainLayout>
  );
};
