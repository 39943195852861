import { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layouts/MainLayout';
import profile from '../assets/images/icons/person.png';
import { Route, Routes } from 'react-router-dom';
import { UserInfo } from '../store';
import { useRecoilValue } from 'recoil';

import moment from 'moment';
import { MainDashboard } from '../components/views/MainDashboard';
import { LeftFolder } from '../components/LeftFolder';
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
/**
 *
 * dashboard page : /dashboard
 */
const TutorialView = () => {
  /// user recoil object
  const userinfo = useRecoilValue(UserInfo);
  /// profile state
  const [profileImg, setProfileImg] = useState(profile);

  useEffect(() => {
    if (userinfo?.member_Profile_imgURL) {
      setProfileImg(
        `${backend_baseUrl}/download/${userinfo?.member_Profile_imgURL}`
      );
    }
  }, [userinfo]);

  return (
    <MainLayout>
      <div className='d-flex'>
        <div className='left_menu'>
          <div className='my_profile text-center'>
            <img src={profileImg} alt='' className='mx-auto rounded-circle' />
            <h5>
              {userinfo?.member_FirstName} {userinfo?.member_LastName}
            </h5>
            <p>
              Member since {moment(userinfo?.member_Created).format('YYYY')}
            </p>
          </div>
          <LeftFolder />
        </div>
        <div className='dash_content d-flex justify-content-center my-4'>
          <div
            className='w-75'
            style={{
              position: 'relative',
              paddingBottom: '65.0994575045208%',
              height: 0,
            }}
          >
            <iframe
              src='https://www.loom.com/embed/7657bd38617640848a165ea2cf0c32e2'
              frameBorder='0'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default TutorialView;
