import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Layout } from '../components/Layouts/Layout';
import { SubmitHandler, useForm } from 'react-hook-form';
import HttpServices from '../services/HttpServices';
type SupportValues = {
  name: string;
  email: string;
  title: string;
  content: string;
};
/**
 *
 * Support page : /support
 */
export const Support = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupportValues>();
  const handleRegistration: SubmitHandler<SupportValues> = (data) => {
    const formData = JSON.stringify(data);
    HttpServices.sendSupportMail(formData)
      .then((res) => {
        // console.log(res);
        alert('Success');
      })
      .catch((err) => {
        alert('an error occurred.');
      });
  };
  return (
    <Layout topClass='box_shadow'>
      <div className='support_page mt-5'>
        <Container>
          <div className='contact_form mx-auto'>
            <h3>Something not working? Tell us here.</h3>
            <h5 className='mt-4'>support ticket:</h5>
            <Form className='mt-4' onSubmit={handleSubmit(handleRegistration)}>
              <Form.Group className='mb-4'>
                <Form.Label>name:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  {...register('name')}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>e-mail:</Form.Label>
                <Form.Control
                  type='email'
                  placeholder=''
                  {...register('email')}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>main problem:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  {...register('title')}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>describe your problem in more detail:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder=''
                  {...register('content')}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <div className='text-end'>
                <button
                  type='submit'
                  className='submit'
                  disabled={
                    errors.content !== undefined ||
                    errors.email !== undefined ||
                    errors.name !== undefined ||
                    errors.title !== undefined
                  }
                >
                  submit
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </Layout>
  );
};
