import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ActivityItem } from "../components/ActivityItem";
import list_icon from "../assets/images/images/list.png";
import { CommunityLayout } from "../components/Layouts/CommunityLayout";
import plus from "../assets/images/images/group_81.png";
import { Activity } from "../models";
export const CommunitySelectedShareFiles = () => {
  const navigate = useNavigate();
  const [inviteModal, setinviteModal] = useState<boolean>(false);
  const [leaveModal, setleaveModal] = useState<boolean>(false);
  const [activityItems, setActivityItems] = useState<Partial<Activity>[]>([
    {
      activity_Name: "Think - Pair - Share",
      activity_PrepTime: "05 m",
      activity_UseTime: "15 m",
      activity_Adaptation: false,
      activity_Desc:
        "Students respond to a prompt individually, then pair up to share and discuss their thoughts further.",
    },
    {
      activity_Name: "Verb Tense Story Chain",
      activity_PrepTime: "05 m",
      activity_UseTime: "15 m",
      activity_Adaptation: false,
      activity_Desc:
        "Students respond to a prompt individually, then pair up to share and discuss their thoughts further.",
    },
    {
      activity_Name: "Action Recall Game",
      activity_PrepTime: "05 m",
      activity_UseTime: "15 m",
      activity_Adaptation: true,
      activity_Desc:
        "Students respond to a prompt individually, then pair up to share and discuss their thoughts further.",
    },
  ]);
  return (
    <CommunityLayout title="Select File to Share" isFriend={false}>
      <div className="ps-md-5">
        <div className="mt-5 ps-0">
          <h4>Recent</h4>
          <div className="mt-3">
            <div className="cell_box row mx-0">
              {activityItems.map((it, idx) => (
                <div className="col-xl-4 col-lg-6 mb-md-4 ps-0" key={idx}>
                  <ActivityItem
                    icon={list_icon}
                    title={it.activity_Name}
                    prep={it.activity_PrepTime}
                    classTime={it.activity_UseTime}
                    description={it.activity_Desc}
                    isAdapt={it.activity_Adaptation || false}
                  />
                </div>
              ))}
            </div>
            <div className="cell_box text-center">
              <button
                type="button"
                className="bg-body border-0 text_info text-decoration-underline"
              >
                See more
              </button>
            </div>
          </div>
        </div>
        <div className=" mt-4 ps-0">
          <h4>
            ACTIVITIES <img src={plus} alt="" width={43} />
          </h4>
          <div className=" mt-3 ">
            <div className="cell_box row ps-3">
              {activityItems.map((it, idx) => (
                <div className="col-xl-4 col-lg-6 mb-md-4 ps-0" key={idx}>
                  <ActivityItem
                    icon={list_icon}
                    title={it.activity_Name}
                    prep={it.activity_PrepTime}
                    classTime={it.activity_UseTime}
                    description={it.activity_Desc}
                    isAdapt={it.activity_Adaptation || false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="cell_box text-center">
            <button
              type="button"
              className="bg-body border-0 text_info text-decoration-underline"
            >
              See more
            </button>
          </div>
        </div>
        <div className=" mt-4 ps-0">
          <h4>
            LESSONS <img src={plus} alt="" width={43} />
          </h4>
          <div className=" mt-3"></div>
          <div className="cell_box text-center">
            <button
              type="button"
              className="bg-body border-0 text_info text-decoration-underline"
            >
              See more
            </button>
          </div>
        </div>
      </div>
    </CommunityLayout>
  );
};
