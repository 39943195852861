import { useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FooterSlider } from '../components/Layouts/Footers/FooterSlider';
import { MainLayout } from '../components/Layouts/MainLayout';
import HttpService from '../services/HttpServices';
import { useRecoilState } from 'recoil';
import { UserInfo } from '../store';
/**
 *
 * New folder page : /build/foldernew
 */
export const FolderNew = () => {
  const navigate = useNavigate();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const [step, setStep] = useState<number>(1);
  const prev = () => {
    setStep((st) => --st);
  };
  const next = () => {
    setStep((st) => ++st);
    console.log('next');
  };
  const last = () => {
    console.log('last');
    navigate('/find/results');
  };
  const stepCount = 3;
  let { parentId, folderId } = useParams();
  const [title, settitle] = useState('');
  const [description, setdescription] = useState('');

  const onSave = async () => {
    const data = {
      folder_name: title,
      folder_desc: description,
      parentId: parentId,
      ownerId: userinfo?.member_Id,
    };
    if (folderId) {
      await HttpService.updateFolderInfo(folderId, JSON.stringify(data));
      if (userinfo?.member_Id) {
        const user = await HttpService.getUserInfo(userinfo.member_Id);
        if (user) setUserinfo(user);
      }
      navigate(-1);
    }
  };
  return (
    <MainLayout>
      <div className='signup_wrap lesson_page'>
        <h4 className='text-center py-3 bg_selected mb-0'>
          {'create a new folder'}
        </h4>
        <div className='description py-3 text-center'>
          organize your collection
        </div>
        <Container className='pt-4'>
          {step == 1 && (
            <Card className='ramp_card border_shadow mx-auto'>
              <Card.Header>
                <div className='d-flex justify-content-between'>
                  <div className='px-md-4 mt-3 d-flex align-items-end'>
                    <div className='cell'>
                      <h5>give this folder a title</h5>
                      <p>anything will do - you can change it later</p>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className='pb-5'>
                <Container>
                  <Row className='sg-row px-lg-4'>
                    <Col className='px-md-4'>
                      <Form.Group className='mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Folder Name'
                          value={title}
                          onChange={(e) => settitle(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer>
                <FooterSlider
                  size={2}
                  current={step}
                  prev={prev}
                  next={next}
                  isNext={title != ''}
                />
              </Card.Footer>
            </Card>
          )}
          {step == 2 && (
            <Card className='ramp_card border_shadow mx-auto'>
              <Card.Header>
                <div className='d-flex justify-content-between'>
                  <div className='px-md-4 mt-3 d-flex align-items-end'>
                    <div className='cell'>
                      <h5>write a description for your folder</h5>
                      <p>what will you put in this folder?</p>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className='pb-5'>
                <Container>
                  <Row className='sg-row px-lg-4'>
                    <Col className='px-md-4'>
                      <Form.Group className='mb-3'>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          value={description}
                          onChange={(e) => setdescription(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer>
                <FooterSlider
                  size={2}
                  current={step}
                  prev={prev}
                  next={next}
                  isNext={true}
                  lastText={'create'}
                  last={onSave}
                />
              </Card.Footer>
            </Card>
          )}
        </Container>
      </div>
    </MainLayout>
  );
};
