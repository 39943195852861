import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../components/Layouts/MainLayout';
import { UserInfo } from '../store';
import { useRecoilValue } from 'recoil';
import {
  userLevel,
} from '../models/defaults';

export const ActivitySelect = () => {
  const navigate = useNavigate();
  const userinfo = useRecoilValue(UserInfo);
  return (
    <MainLayout>
      <div className='signup_wrap'>
        <Container className='pt-5 mt-5'>
          <Card className='sign_card border_shadow mx-auto'>
            <Card.Header className='px-2'>
              <div className='d-flex justify-content-center'>
                <div className='title'>
                  <h4 className='text-center'>add a new activity</h4>
                </div>
              </div>
            </Card.Header>
            <Card.Body className='pb-5'>
              <Container>
                <Row className='sg-row px-lg-4 mt-5 pt-3'>
                  <Col className='px-md-2 text-center'>
                    <button
                      className='bg_warn rounded_3 py-3 px-4 fs-4 w_100'
                      disabled={userinfo?.member_Type !== userLevel.contributor}
                      onClick={() => {
                        navigate('/build/template');
                      }}
                    >
                      <h5 className='mb-0'>create a new activity</h5>
                    </button>
                  </Col>
                  <Col className='px-md-2 text-center'>
                    <button
                      className='bg_warn rounded_3 py-3 px-2 fs-4 w_100'
                      onClick={() => {
                        navigate('/find');
                      }}
                    >
                      <h5 className='mb-0'>find an activity or adapt</h5>
                    </button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </MainLayout>
  );
};
