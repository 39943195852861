import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap";
import close from "../../assets/images/icons/red_close.svg";
import gray_find from "../../assets/images/icons/gray_find.svg";
import man_icon from "../../assets/images/icons/input_man_icon.svg";
import fist from "../../assets/images/images/two_fist.png";
import { ManItem } from "../ManItem";
interface Props {
  show: boolean;
  onOk: () => void;
}
export const InviteOtherModal = (props: Props) => {
  const [invite, setInvite] = useState<boolean>(false);
  useEffect(() => {
    if (props.show) setInvite(false);
  }, [props.show]);

  if (invite) {
    return (
      <Modal
        show={props.show}
        onHide={props.onOk}
        className="dashboard_video"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title>
            invitation sent!
            <img
              src={close}
              alt=""
              onClick={() => {
                props.onOk();
              }}
              className="closeBtn"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Container>
            <div>
              <p className="text-center fs-5">
                your friend will see their invitation in their community tab
              </p>
            </div>
            <div className="text-center my-3">
              <img src={fist} alt="" className="mx-auto" width={260} />
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="border-0 bg_warn rounded_3 py-2 wd-200  px-4 fs-4"
                onClick={() => {
                  props.onOk();
                }}
              >
                back to groups
              </button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>
          select friends to invite
          <img
            src={close}
            alt=""
            onClick={() => {
              props.onOk();
            }}
            className="closeBtn"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div>
            <p className="text-center fs-5">
              Search for friends and invite them to the group
            </p>
          </div>
          <div className="friend_list">
            <InputGroup className="mb-2 my_effect">
              <InputGroup.Text id="basic-addon1">
                <img src={gray_find} alt="" />
              </InputGroup.Text>
              <FormControl
                placeholder="search for friends to add"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <ManItem
              name="Thomas Anderson"
              address="Chiang Mai. Thailand"
              icon={man_icon}
              isAdd={true}
            />
            <ManItem
              name="Firstname Lastname"
              address="City, Country"
              icon={man_icon}
              isAdd={true}
            />
            <ManItem
              name="Firstname Lastname"
              address="City, Country"
              icon={man_icon}
              isAdd={true}
            />
            <ManItem
              name="Firstname Lastname"
              address="City, Country"
              icon={man_icon}
            />
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="border-0 bg_warn rounded_3 py-2 wd-180  px-4 fs-4"
              onClick={() => {
                setInvite(true);
              }}
            >
              invite
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
