import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../components/Layouts/MainLayout';
import back_icon from '../assets/images/icons/white-left-arrow.svg';
import friend1 from '../assets/images/images/friend1.png';
import friend2 from '../assets/images/images/friend2.png';
import friend3 from '../assets/images/images/friend3.png';
import adapt_icon from '../assets/images/images/adapt.png';
import build_icon from '../assets/images/images/build.png';
import chat_plus from '../assets/images/icons/chat_plus.svg';
import { Card, Container } from 'react-bootstrap';
export const CollaborateChat = () => {
  const navigate = useNavigate();
  const [createModalshow, setCreateModalshow] = useState<boolean>(false);
  return (
    <MainLayout>
      <div className='template_wrap'>
        <div className='header_s mt-3'>
          <button
            className='back_btn border-0 bg-body'
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={back_icon} alt='' /> back
          </button>
        </div>
        <div className='body py-3'>
          <Container>
            <div className='row px-3 mx-0 mt-4'>
              <div className='col-md-6 col-lg-4'>
                <div className='group'>
                  <div className='images d-flex '>
                    <div className='item '>
                      <img
                        src={friend1}
                        alt=''
                        height={68}
                        className='selected'
                      />
                    </div>
                    <div className='item'>
                      <img src={friend2} alt='' height={68} />
                    </div>
                    <div className='item'>
                      <img src={friend3} alt='' height={68} />
                    </div>
                    <div className='number_box'>+5</div>
                  </div>
                  <div className='caption text-start'>
                    <h3
                      onClick={() => {
                        navigate('/build/collaborate/detail');
                      }}
                    >
                      K2 Teachers 2021
                    </h3>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-8 text-end'>
                <button className='rounded bg-body py-1 px-4'>Invite</button>
              </div>
            </div>
            <div className='mx-0 px-3 mt-4'>
              <div className='d-flex justify-content-start record_box'>
                <div className='main_icon'>
                  <img src={friend1} alt='' height={35} />
                </div>
                <div className='chat_box ms-2'>
                  <div className='attend_comment'>Sally Sartain</div>
                  <div className='rounded rounded-3 px-3 py-2 message'>
                    Hey, everyone! Welcome to the group.{' '}
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-end record_box'>
                <div className='chat_box me-2'>
                  <div className='attend_comment'></div>
                  <div className='rounded rounded-3 px-3 py-2 message send'>
                    Edusfere is amazing!!!
                  </div>
                </div>
                <div className='main_icon'>
                  <img src={friend2} alt='' height={35} />
                </div>
              </div>
              <div className='d-flex justify-content-start record_box'>
                <div className='main_icon'>
                  <img src={friend3} alt='' height={35} />
                </div>
                <div className='chat_box ms-2'>
                  <div className='attend_comment'>Penelope Rhodes</div>
                  <div className='rounded rounded-3 px-3 py-2 message'>
                    I agree! Where has this been all my life?
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-start record_box'>
                <div className='main_icon'>
                  <img src={friend3} alt='' height={35} />
                </div>
                <div className='chat_box ms-2'>
                  <div className='attend_comment'>Penelope Rhodes</div>
                  <div className='rounded rounded-3 px-3 py-2 message'>
                    I agree! Where has this been all my life?
                  </div>
                  <Card className='default'>
                    <Card.Header className='d-flex justify-content-between'>
                      <div className='title_box'>
                        <div className='rounded rounded-2 bg-white symbol'></div>
                        <b className='ms-1'>Verb Tense Story Chain</b>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className='d-flex'>
                        <div className='content'>
                          <div>
                            <div>
                              <span>
                                05 m <b>prep</b>
                              </span>
                              <span className='ms-2'>
                                20 m <b>class time</b>
                              </span>
                            </div>
                            <p>
                              Students will tell a story using a selected verb
                              tense by taking turns writing one sentence at a
                              time.
                            </p>
                          </div>
                        </div>
                        <div className='controls d-flex align-content-center flex-wrap'>
                          <div className='my-3'>
                            <img src={adapt_icon} className='mx-auto' alt='' />
                          </div>
                          <div className='my-3'>
                            <img src={build_icon} className='mx-auto' alt='' />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className='send_box'>
              <img src={chat_plus} alt='' />
              <input type="text" />
              <button>Send</button>
            </div>
          </Container>
        </div>
      </div>
    </MainLayout>
  );
};
