import { Card } from 'react-bootstrap';
import { Activity } from '../models';
import list_icon from '../assets/images/images/list.png';
import { curActivity } from '../store';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
interface Props {
  kindClass?: string;
  activity: Activity;
}

export const AdaptItem = (props: Props) => {
  const navigate = useNavigate();
  const setCurActivity = useSetRecoilState(curActivity);
  const goDetail = () => {
    setCurActivity(props.activity);
    if (props.activity.isDraft) {
      if (props.activity.activity_Adaptation) {
        navigate('/build/adapt/detail');
      } else {
        navigate('/build/template');
      }
    } else {
      navigate('/build/template/detail/0');
    }
  };
  return (
    <Card className={`${props.kindClass}`}>
      <Card.Header className='d-flex  title_box cursor' onClick={() => goDetail()}>
        <img src={list_icon} alt='' height={20} className='my-auto' />
        <b className='ms-1'>{props.activity.activity_Name}</b>
      </Card.Header>
      <Card.Body>
        <div className='d-flex'>
          <div className='content' onClick={() => {}}>
            <div>
              <span>
                {props.activity.activity_PrepTime} <b>prep</b>
              </span>
            </div>
            <div>
              <span>
                {props.activity.activity_UseTime} <b>class time</b>
              </span>
            </div>
           
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
