import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Layout } from '../components/Layouts/Layout';
import face_log from '../assets/images/images/image_6@2x.png';
import insta_log from '../assets/images/images/image_3@2x.png';
import link_log from '../assets/images/images/image_4@2x.png';
import { SubmitHandler, useForm } from 'react-hook-form';
import HttpServices from '../services/HttpServices';
/// useform hook type
type ContactValues = {
  name: string;
  email: string;
  note_title: string;
  note: string;
};
/**
 *
 * Contact page /contact
 */
export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactValues>();
  /// submit action
  const handleRegistration: SubmitHandler<ContactValues> = (data) => {
    const formData = JSON.stringify(data);
    HttpServices.sendContact(formData)
      .then((res) => {
        alert('Success');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout topClass='box_shadow'>
      <div className='contact_wrap'>
        <Container className='mt-5'>
          <div className='contact_form mx-auto'>
            <h3>We’d love to hear from you!</h3>
            <h5 className='mt-4'>contact form:</h5>
            <Form className='mt-4' onSubmit={handleSubmit(handleRegistration)}>
              <Form.Group className='mb-4'>
                <Form.Label>name:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  {...register('name')}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>e-mail:</Form.Label>
                <Form.Control
                  type='email'
                  placeholder=''
                  {...register('email')}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>note title :</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  {...register('note_title')}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>your note:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder=''
                  {...register('note')}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <div className='text-end'>
                <button
                  type='submit'
                  className='submit'
                  disabled={
                    errors.email !== undefined ||
                    errors.name !== undefined ||
                    errors.note_title !== undefined
                  }
                >
                  submit
                </button>
              </div>
            </Form>
          </div>
        </Container>
        <div className='footer_link text-center'>
          <h6>find us on:</h6>
          <div>
            <img src={face_log} alt='' width={98} />
            <img src={insta_log} alt='' width={98} />
            <img src={link_log} alt='' width={98} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
