import React, { useRef, useState } from 'react';
import { Container, Form, Spinner } from 'react-bootstrap';
import { MainLayout } from '../components/Layouts/MainLayout';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserInfo } from '../store';
import { useRecoilValue } from 'recoil';
import { ConfirmModal } from '../components/modals/ConfirmModal';
import { MessageString } from '../models/defaults';
import { useSendFeedback } from '../services/Query';
import red_plus_icon from '../assets/images/icons/red_plus.svg';
import InputFile from '../components/InputFile';
import { PromptModal } from '../components/modals/PromptModal';
type ContactValues = {
  name: string;
  email: string;
  note: string;
};
/**
 *
 * Help page : /help
 */
export const Help = () => {
  const userinfo = useRecoilValue(UserInfo);
  const [confirm, setconfirm] = useState(false);
  const sendData = useRef<FormData>();
  const g_UploadFiles = useRef<(File | null)[]>([]);
  const fileIds = useRef<string[]>([]);
  const [removeFileInput, setremoveFileInput] = useState<number[]>([]);
  const [resetName, setresetName] = useState<string | undefined>(undefined);
  const [uploadSize, setUploadSize] = useState<number>(0);
  const [successShow, setsuccessShow] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ContactValues>({
    defaultValues: {
      name: `${userinfo?.member_FirstName} ${userinfo?.member_LastName}`,
      email: userinfo?.member_Email,
      note: '',
    },
  });
  const { isLoading, mutate: sendFeedBack } = useSendFeedback();
  /// submit action
  const handleRegistration: SubmitHandler<ContactValues> = (data) => {
    const formData = new FormData();
    if (g_UploadFiles.current.length > 0) {
      let i_pos = 0;
      for (const it of g_UploadFiles.current) {
        if (it != null) {
          formData.append(`uploadFile${i_pos}`, it);
          i_pos++;
        }
      }
    }
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('note', data.note);
    sendData.current = formData;
    console.log(formData);
    setconfirm(true);
  };
  const onSendEmail = () => {
    setconfirm(false);
    if (sendData.current) {
      sendFeedBack(sendData.current, {
        onSuccess: () => {
          setsuccessShow(true);
          setUploadSize(0);
          g_UploadFiles.current = [];
          setremoveFileInput([]);
          setresetName('');
          reset();
        },
      });
    }
  };

  const onUpdateUploadFile = (
    idx: number,
    vfile: File,
    oId: string | undefined
  ) => {
    if (g_UploadFiles.current.length > idx) {
      g_UploadFiles.current[idx] = vfile;
    } else {
      g_UploadFiles.current.push(vfile);
    }
    if (oId) {
      if (!fileIds.current.includes(oId)) {
        fileIds.current.push(oId);
      }
    }
  };
  const renderFileInput = () => {
    const elems: React.ReactNode[] = [];
    let label = 2;
    for (let i = 0; i < uploadSize; i++) {
      const removed = removeFileInput.includes(i + 1);
      if (!removed) {
        elems.push(
          <div className='mt-1'>
            <InputFile
              nIndex={i + 1}
              hideLabel={true}
              type={'image/png, image/jpeg, image/jpg'}
              onSetFile={onUpdateUploadFile}
              onRemove={(idx, oId) => {
                console.log(idx);
                if (g_UploadFiles.current.length > idx) {
                  g_UploadFiles.current[idx] = null;
                }
                setremoveFileInput((prev) => [...prev, idx]);
                if (oId) {
                  if (!fileIds.current.includes(oId)) {
                    fileIds.current.push(oId);
                  }
                }
              }}
            />
          </div>
        );
      }
      label++;
    }
    return elems;
  };
  return (
    <MainLayout wrapClass='box_shadow'>
      {/* <Container className='py-3 helps'>
        <h2 className='text-center'>Frequently Asked Questions</h2>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <img src={help_com_icon} alt='' className='me-3' /> Short
              description of category for questions
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <h5>This is where the question will go?</h5>
                <div className='youtube d-flex justify-content-center align-items-center'>
                  <img src={youtube} alt='' />
                </div>
                <p>
                  The answer will go in this area. It would likely take up more
                  than one line and possibly include helpful links users can
                  access to navigate throughout the site.
                </p>
                <p>
                  1. This is the first line of direcions for the user to follow.{' '}
                  <br />
                  2. This is the second line of directions for the user to
                  follow. <br />
                  3. This is the third line of directions for the user to
                  follow. <br /> 4. This is the fourth line of directions for
                  the user to follow.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>
              <img src={help_com_icon} alt='' className='me-3' />
              Short description of category for questions
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <h5>This is where the question will go?</h5>
                <div className='youtube d-flex justify-content-center align-items-center'>
                  <img src={youtube} alt='' />
                </div>
                <p>
                  The answer will go in this area. It would likely take up more
                  than one line and possibly include helpful links users can
                  access to navigate throughout the site.
                </p>
                <p>
                  1. This is the first line of direcions for the user to follow.{' '}
                  <br />
                  2. This is the second line of directions for the user to
                  follow. <br />
                  3. This is the third line of directions for the user to
                  follow. <br /> 4. This is the fourth line of directions for
                  the user to follow.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>
              <img src={help_com_icon} alt='' className='me-3' />
              Short description of category for questions
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <h5>This is where the question will go?</h5>
                <div className='youtube d-flex justify-content-center align-items-center'>
                  <img src={youtube} alt='' />
                </div>
                <p>
                  The answer will go in this area. It would likely take up more
                  than one line and possibly include helpful links users can
                  access to navigate throughout the site.
                </p>
                <p>
                  1. This is the first line of direcions for the user to follow.{' '}
                  <br />
                  2. This is the second line of directions for the user to
                  follow. <br />
                  3. This is the third line of directions for the user to
                  follow. <br /> 4. This is the fourth line of directions for
                  the user to follow.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3'>
            <Accordion.Header>
              <img src={help_com_icon} alt='' className='me-3' />
              Short description of category for questions
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <h5>This is where the question will go?</h5>
                <div className='youtube d-flex justify-content-center align-items-center'>
                  <img src={youtube} alt='' />
                </div>
                <p>
                  The answer will go in this area. It would likely take up more
                  than one line and possibly include helpful links users can
                  access to navigate throughout the site.
                </p>
                <p>
                  1. This is the first line of direcions for the user to follow.{' '}
                  <br />
                  2. This is the second line of directions for the user to
                  follow. <br />
                  3. This is the third line of directions for the user to
                  follow. <br /> 4. This is the fourth line of directions for
                  the user to follow.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='4'>
            <Accordion.Header>
              <img src={help_com_icon} alt='' className='me-3' />
              Short description of category for questions
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <h5>This is where the question will go?</h5>
                <div className='youtube d-flex justify-content-center align-items-center'>
                  <img src={youtube} alt='' />
                </div>
                <p>
                  The answer will go in this area. It would likely take up more
                  than one line and possibly include helpful links users can
                  access to navigate throughout the site.
                </p>
                <p>
                  1. This is the first line of direcions for the user to follow.{' '}
                  <br />
                  2. This is the second line of directions for the user to
                  follow. <br />
                  3. This is the third line of directions for the user to
                  follow. <br /> 4. This is the fourth line of directions for
                  the user to follow.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <p className='text-center mt-3 comment'>
          For other questions or to contact customer support call <br />{' '}
          <a href="#" title=''>1-800-000-0000</a>  or email <a href="#">support@email.com</a>
        </p>
      </Container> */}
      <div className='contact_wrap'>
        <Container className='mt-5'>
          <div className='contact_form mx-auto'>
            <h3>send feedback</h3>
            <p className='text-muted fw-light fnt_robo'>
              experiencing issues or have a suggestion for improvements? tell us
              here!
            </p>
            <Form className='mt-4' onSubmit={handleSubmit(handleRegistration)}>
              <Form.Group className='mb-4'>
                <Form.Label>Your name:</Form.Label>
                <Form.Control
                  type='text'
                  readOnly={true}
                  placeholder=''
                  {...register('name')}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <Form.Label>Your email:</Form.Label>
                <Form.Control
                  type='email'
                  readOnly={true}
                  placeholder=''
                  {...register('email')}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <Form.Label>Message :</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder=''
                  {...register('note', { required: true })}
                />
                <Form.Text className='text-muted d-none'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Attached Files:
                  <br />
                  <p className='text-muted fnt_robo'>
                    you can attach screenshots of your issue here
                  </p>
                </Form.Label>
                <InputFile
                  nIndex={0}
                  hideLabel={true}
                  fileName={resetName}
                  type={'image/png, image/jpeg, image/jpg'}
                  onSetFile={onUpdateUploadFile}
                />
                {renderFileInput()}
              </Form.Group>
              <div className='text-center my-2'>
                <img
                  src={red_plus_icon}
                  className='cursor'
                  alt=''
                  onClick={() => {
                    setresetName(undefined);
                    setUploadSize((prev) => ++prev);
                  }}
                />
              </div>
              <div className='text-end'>
                <button
                  type='submit'
                  className='submit'
                  disabled={errors.note !== undefined || isLoading}
                >
                  {isLoading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      className='me-2'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  submit
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
      <ConfirmModal
        show={confirm}
        content={MessageString.feedbackMessage}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          setconfirm(false);
        }}
        onDiscard={() => {
          onSendEmail();
        }}
      />
      <PromptModal
        show={successShow}
        onOk={() => setsuccessShow(false)}
        title={'success'}
        message={'your feedback submitted successfully!'}
      />
    </MainLayout>
  );
};
