import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../components/Layouts/MainLayout';
import back_icon from '../assets/images/icons/white-left-arrow.svg';
import friend1 from '../assets/images/images/friend1.png';
import friend2 from '../assets/images/images/friend2.png';
import friend3 from '../assets/images/images/friend3.png';
import help_icon from '../assets/images/icons/symbol_help.svg';
import tri_icon from '../assets/images/icons/symbol_tri.svg';
import { Container } from 'react-bootstrap';
import { CreateNewGroupModal } from '../components/modals/CreateNewGroupModal';
export const Collaborate = () => {
  const navigate = useNavigate();
  const [createModalshow, setCreateModalshow] = useState<boolean>(false);
  return (
    <MainLayout>
      <div className='template_wrap'>
        <div className='header'>
          <button
            className='back_btn'
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={back_icon} alt='' /> back
          </button>
          <div>
            <h4>Collaborate in groups</h4>
            <div className='comment'>we are each other’s greatest resource</div>
          </div>
          <button className='go_btn'>see a demo</button>
        </div>
        <div className='body py-3'>
          <Container>
            <h3>My Groups</h3>
            <div className='row px-3 mx-0 mt-4'>
              <div className='col-md-6 col-lg-4'>
                <div className='group'>
                  <div className='images d-flex justify-content-center'>
                    <div className='item '>
                      <img
                        src={friend1}
                        alt=''
                        height={68}
                        className='selected'
                      />
                    </div>
                    <div className='item'>
                      <img src={friend2} alt='' height={68} />
                    </div>
                    <div className='item'>
                      <img src={friend3} alt='' height={68} />
                    </div>
                    <div className='number_box'>+5</div>
                  </div>
                  <div className='caption'>
                    <h3
                      onClick={() => {
                        navigate('/build/collaborate/detail');
                      }}
                    >
                      K2 Teachers 2021
                    </h3>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4'>
                <div className='group'>
                  <div className='images d-flex justify-content-center'>
                    <div className='item'>
                      <img src={friend1} alt='' height={68} />
                    </div>
                    <div className='item'>
                      <img src={friend2} alt='' height={68} />
                    </div>
                    <div className='item'>
                      <img src={friend3} alt='' height={68} />
                    </div>
                    <div className='number_box'>+5</div>
                  </div>
                  <div className='caption'>
                    <h3
                      onClick={() => {
                        navigate('/build/collaborate/detail');
                      }}
                    >
                      K3 Teachers 2021
                    </h3>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4'>
                <div
                  className='group'
                  onClick={() => {
                    setCreateModalshow(true);
                  }}
                >
                  <div className='images d-flex justify-content-center'>
                    <div className='item'>
                      <div className='circle gray'></div>
                    </div>
                    <div className='item'>
                      <div className='circle red'>+</div>
                    </div>
                    <div className='item'>
                      <div className='circle gray'></div>
                    </div>
                  </div>
                  <div className='caption'>
                    <h3>Add new</h3>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className='control_box d-flex justify-content-end'>
          <div>
            <div className='circle blue'>
              <img src={help_icon} alt='' />
            </div>
            <div className='circle red mt-2'>
              <img src={tri_icon} alt='' className='ms-2' />
            </div>
          </div>
        </div>
      </div>
      <CreateNewGroupModal
        show={createModalshow}
        onOk={() => {
          setCreateModalshow(false);
        }}
      />
    </MainLayout>
  );
};
